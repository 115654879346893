$fontFamily: "Roboto", sans-serif;

@media screen and (min-width: 320px) {
  .navbar {
    background-color: #111827;
    font-family: $fontFamily;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;

    .navbar-content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .navbar-logo {
      margin: 0 2rem;

      img {
        width: 100px;
      }
    }

    .navbar-nav {
      display: flex;
      align-items: center;

      .nav-item {
        margin: 0 0.5rem;

        .nav-link {
          color: white;
          font-size: 1.2rem;  // Increased font size
          font-weight: bold;  // Made text bold
          &:hover {
            color: #d35252;
          }
        }

        .dropdown-menu {
          background-color: #111827;
          border: none;

          .dropdown-item {
            color: white;
            font-size: 1.2rem;  // Increased font size
            font-weight: bold;  // Made text bold
            &:hover {
              background-color: #d35252;
              color: white;
            }
          }
        }
      }
    }

    .menu__icon {
      width: 2rem;
      height: 2rem;
      padding: 0.25rem;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      font-family: inherit;

      span {
        display: block;
        width: 100%;
        height: 0.125rem;
        border-radius: 0.125rem;
        background-color: #d35252;
        box-shadow: 0 0.5px 2px 0 hsl(0, 45%, 33%);
        transition: background-color 0.4s, transform 0.4s, opacity 0.4s;

        &:nth-child(2) {
          margin-top: 0.563rem;
        }
      }

      &:hover {
        span:nth-child(1) {
          background-color: #d35252;
          transform: translateY(0.688rem) rotate(-45deg);
        }

        span:nth-child(2) {
          transform: translateX(-80%);
          opacity: 0;
        }

        span:nth-child(3) {
          background-color: #d35252;
          transform: translateY(-0.688rem) rotate(45deg);
        }
      }
    }
  }
}
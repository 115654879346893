$fontColor: #d35252;
$backgroundColor: #0a1128;
$accentColor: #e63946;
$textColor: #ffffff;

.landing-container {
  background-image: url("../img/game_avatar.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  color: $textColor;

  .content-container {
    text-align: center;
    max-width: 700px;
    margin: auto;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 40px 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);

    .text-container {
      h2 {
        font-size: 28px;
        color: $textColor;
        margin-bottom: 1rem;
      }

      h1 {
        color: $accentColor;
        font-size: 36px;
        margin-bottom: 1.5rem;
      }

      p {
        font-family: "DM Sans", sans-serif;
        font-size: 18px;
        margin-bottom: 2rem;
        line-height: 1.6;

        span {
          color: $accentColor;
          font-size: 20px;
          font-weight: bold;
        }
      }

      .button-container {
        display: flex;
        justify-content: center;
        gap: 10px; /* Reduced gap for better symmetry */
        margin-bottom: 0; /* Ensure the section ends right after the logos */

        .store-logo {
          width: 170px; /* Reduced size for symmetry */
          height: auto;
          cursor: pointer;
          transition: transform 0.3s ease;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

@media screen and (min-width: 768px) {
  .landing-container {
    .content-container {
      .text-container {
        h2 {
          font-size: 32px;
        }

        h1 {
          font-size: 40px;
        }

        p {
          font-size: 20px;
        }
      }

      .button-container {
        gap: 30px; /* Adjusted gap for better balance */

        .store-logo {
          width: 160px;
        }
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .landing-container {
    padding: 40px;

    .content-container {
      .text-container {
        h2 {
          font-size: 36px;
        }

        h1 {
          font-size: 44px;
        }

        p {
          font-size: 22px;
        }
      }

      .button-container {
        .store-logo {
          width: 180px;
        }
      }
    }
  }
}

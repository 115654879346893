.admin-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 120px);
    background-color: #f3f4f6;
    padding: 1rem;
  
    .login-card {
      background-color: white;
      border-radius: 1rem;
      box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
      width: 100%;
      max-width: 420px;
      padding: 2rem;
      transition: transform 0.2s ease;
  
      &:hover {
        transform: translateY(-2px);
      }
  
      .login-header {
        text-align: center;
        margin-bottom: 2rem;
  
        h1 {
          font-size: 1.875rem;
          font-weight: 700;
          color: #1f2937;
          margin-bottom: 0.5rem;
        }
  
        p {
          color: #6b7280;
          font-size: 0.875rem;
        }
      }
  
      .login-form {
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
  
        .error-message {
          background-color: #fee2e2;
          color: #991b1b;
          padding: 0.75rem;
          border-radius: 0.5rem;
          font-size: 0.875rem;
          display: flex;
          align-items: center;
          gap: 0.5rem;
  
          .icon {
            width: 1.25rem;
            height: 1.25rem;
            stroke: currentColor;
          }
        }
  
        .form-group {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
  
          label {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            color: #ffffff;
            font-weight: 500;
            font-size: 0.875rem;
  
            .icon {
              width: 1rem;
              height: 1rem;
              color: #6b7280;
            }
          }
  
          input {
            padding: 0.75rem 1rem;
            border: 1px solid #e5e7eb;
            border-radius: 0.5rem;
            font-size: 0.875rem;
            transition: all 0.2s ease;
  
            &:focus {
              outline: none;
              border-color: #4f46e5;
              box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
            }
  
            &.error {
              border-color: #dc2626;
              
              &:focus {
                box-shadow: 0 0 0 2px rgba(220, 38, 38, 0.2);
              }
            }
  
            &:disabled {
              background-color: #f3f4f6;
              cursor: not-allowed;
            }
          }
  
          .password-input {
            position: relative;
  
            input {
              padding-right: 2.5rem;
              width: 100%;
            }
  
            .toggle-password {
              position: absolute;
              right: 0.75rem;
              top: 50%;
              transform: translateY(-50%);
              background: none;
              border: none;
              padding: 0.25rem;
              color: #6b7280;
              cursor: pointer;
              transition: color 0.2s ease;
  
              &:hover {
                color: #4b5563;
              }
  
              &:disabled {
                cursor: not-allowed;
                opacity: 0.5;
              }
  
              .icon {
                width: 1.25rem;
                height: 1.25rem;
              }
            }
          }
        }
  
        .login-button {
          background-color: #4f46e5;
          color: white;
          padding: 0.75rem 1.5rem;
          border-radius: 0.5rem;
          font-weight: 500;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
  
          &:hover:not(:disabled) {
            background-color: #4338ca;
          }
  
          &:focus {
            outline: none;
            box-shadow: 0 0 0 2px #e0e7ff;
          }
  
          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
  
          &.loading {
            background-color: #6b7280;
          }
  
          .spinner {
            width: 1.25rem;
            height: 1.25rem;
            animation: spin 1s linear infinite;
  
            circle {
              stroke: currentColor;
              stroke-linecap: round;
              animation: dash 1.5s ease-in-out infinite;
            }
          }
        }
  
        .development-note {
          margin-top: 1rem;
          padding: 1rem;
          background-color: #f3f4f6;
          border-radius: 0.5rem;
          font-size: 0.875rem;
  
          p {
            color: #4b5563;
            margin-bottom: 0.5rem;
            font-weight: 500;
          }
  
          code {
            display: block;
            background-color: #e5e7eb;
            padding: 0.5rem;
            border-radius: 0.25rem;
            color: #1f2937;
          }
        }
      }
    }
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
  
  // Dark mode support
  .dark {
    .admin-login-container {
      background-color: #111827;
  
      .login-card {
        background-color: #1f2937;
  
        .login-header {
          h1 {
            color: #f9fafb;
          }
  
          p {
            color: #9ca3af;
          }
        }
  
        .login-form {
          input {
            background-color: #374151;
            border-color: #4b5563;
            color: #f9fafb;
  
            &::placeholder {
              color: #9ca3af;
            }
  
            &:focus {
              border-color: #6366f1;
            }
  
            &:disabled {
              background-color: #1f2937;
            }
          }
  
          .development-note {
            background-color: #374151;
  
            code {
              background-color: #1f2937;
              color: #e5e7eb;
            }
          }
        }
      }
    }
  }
  
  // Responsive design
  @media (max-width: 640px) {
    .admin-login-container {
      padding: 1rem;
  
      .login-card {
        padding: 1.5rem;
  
        .login-header {
          h1 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
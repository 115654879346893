/* Container and general styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #0B1221;
  font-family: Arial, sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  color: #f0f0f0;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 1rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
  }
}

/* CrewCastleDetails component styles */
.crew-castle-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 2rem;
  background: linear-gradient(180deg, rgba(19, 30, 50, 0.6) 0%, rgba(19, 30, 50, 0.8) 100%);
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1rem;
  }
}

.crew-castle-header {
  width: 100%;
  text-align: center;
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }
}

.back-button {
  margin-bottom: 2rem;
  padding: 0.75rem 1.5rem;
  background: linear-gradient(145deg, #1a3a66, #162f52);
  color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    background: linear-gradient(145deg, #234f7d, #1d4268);
    transform: translateY(-2px);
  }
}

.castle-table {
  width: 100%;
  min-width: 900px;
  border-collapse: separate;
  border-spacing: 0;
  background: rgba(10, 18, 33, 0.6);
  border-radius: 0.5rem;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.castle-table thead th {
  padding: 1rem;
  background-color: rgba(13, 29, 52, 0.95);
  color: #ffffff;
  font-weight: 600;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 10;
  font-size: 1.1rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border-bottom: 1px solid rgba(27, 44, 78, 0.6);
}

.castle-table tbody td {
  padding: 1rem;
  color: #e5e7eb;
  font-size: 1rem;
  text-align: left;
  white-space: nowrap;
  border-bottom: 1px solid rgba(27, 44, 78, 0.6);
  transition: background-color 0.2s ease;
}

.row-even {
  background-color: rgba(26, 58, 102, 0.4);
}

.row-odd {
  background-color: rgba(15, 45, 82, 0.4);
}

.castle-table tbody tr {
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background-color: rgba(35, 79, 125, 0.8);
    transform: translateX(4px);
  }
}

/* Table container for scrolling */
.table-container {
  width: 100%;
  max-width: 1400px;
  background: rgba(19, 30, 50, 0.6);
  border-radius: 1rem;
  padding: 1rem;
  margin: 0 auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  
  @media (max-width: 768px) {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(31, 41, 55, 0.5);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #374151;
    border-radius: 4px;
    
    &:hover {
      background: #4b5563;
    }
  }
}

@media (max-width: 768px) {
  .castle-table thead th,
  .castle-table tbody td {
    padding: 0.75rem;
    font-size: 0.9rem;
  }
}

/* Print styles */
@media print {
  body {
    background-color: white;
  }

  .crew-castle-details {
    background: none;
    box-shadow: none;
  }

  .back-button {
    display: none;
  }

  .castle-table {
    border: 1px solid #ddd;

    th, td {
      border: 1px solid #ddd;
      color: black;
      background-color: white !important;
    }

    th {
      background-color: #f5f5f5 !important;
    }

    tr:hover {
      background-color: transparent !important;
      transform: none !important;
    }
  }
}
.package-container {
  background-color: var(--background-color);
  border-radius: 10px;
  padding: 15px;
  width: 100%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;

  @media (max-width: 480px) {
    padding: 12px;
  }

  .token-amount {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;

    .img {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 30px;
        height: auto;
        flex-shrink: 0;
      }

      div {
        h3 {
          margin: 0;
          color: var(--text-color);
          font-size: 1.5rem;
          font-weight: 600;
          line-height: 1.3;
        }

        p {
          margin: 5px 0 0;
          color: var(--secondary-text-color);
          font-size: 1rem;
          line-height: 1.3;

          @media (max-width: 480px) {
            font-size: 0.9rem;
          }
        }
      }
    }

    .discount {
      background-color: #ff4444;
      padding: 5px 10px;
      border-radius: 5px;
      text-align: center;
      width: fit-content;

      h3 {
        margin: 0;
        color: white;
        font-size: 1rem;
      }

      p {
        margin: 0;
        color: white;
        font-size: 0.8rem;
      }
    }
  }

  .price-container {
    display: flex;
    flex-direction: column;
    gap: 12px;

    .price-text {
      display: flex;
      align-items: baseline;
      gap: 5px;

      h3 {
        margin: 0;
        color: var(--text-color);
        font-size: 1.5rem;
        font-weight: bold;
      }

      p {
        margin: 0;
        color: var(--secondary-text-color);
        font-size: 0.9rem;
      }
    }

    button {
      width: 100%;
      padding: 10px;
      background-color: #0070ba;
      color: white;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 0.95rem;
      font-weight: 600;
      transition: all 0.2s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover:not(:disabled) {
        background-color: #005ea6;
      }

      &:active:not(:disabled) {
        transform: translateY(1px);
      }

      &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
      }

      &.loading {
        position: relative;
        cursor: wait;
        padding-right: 40px;

        &::after {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          top: 50%;
          right: 10px;
          margin-top: -8px;
          border: 2px solid rgba(255, 255, 255, 0.3);
          border-radius: 50%;
          border-top-color: #fff;
          animation: spin 0.8s linear infinite;
        }

        @media (max-width: 480px) {
          padding-right: 10px;
          
          &::after {
            right: 50%;
            margin-right: -25px;
          }
        }
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Dark mode styles
:root[class~="dark"] .package-container {
  background-color: #2a2a2a;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);

  .token-amount {
    .img {
      div {
        h3 {
          color: #ffffff;
        }
        p {
          color: #cccccc;
        }
      }
    }
  }

  .price-text {
    h3 {
      color: #ffffff;
    }
    p {
      color: #cccccc;
    }
  }

  button:disabled {
    background-color: #444444;
    color: #888888;
  }
}

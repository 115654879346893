.character-lookup-panel {
  .data-item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #111827;
    border-radius: 8px;
    transition: all 0.2s ease;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #1a2234;
    }

    label {
      font-weight: 600;
      color: #9ca3af;
      min-width: 160px;
      flex-shrink: 0;
      text-align: center;
    }

    .search-controls {
      flex: 1;

      form {
        width: 100%;

        .search-inputs {
          display: flex;
          gap: 12px;
          width: 100%;

          .search-type {
            background-color: #1f2937;
            border: 1px solid #374151;
            color: #fff;
            padding: 0.75rem 1rem;
            border-radius: 8px;
            font-size: 0.875rem;
            min-width: 150px;
            cursor: pointer;

            &:focus {
              outline: none;
              border-color: #3b82f6;
              box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
            }

            option {
              background-color: #1f2937;
              color: #fff;
            }
          }

          .search-input {
            background-color: #1f2937;
            border: 1px solid #374151;
            color: #fff;
            padding: 0.75rem 1rem;
            border-radius: 8px;
            font-size: 0.875rem;
            flex: 1;

            &:focus {
              outline: none;
              border-color: #3b82f6;
              box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
            }

            &::placeholder {
              color: #6b7280;
            }
          }

          .search-btn {
            background-color: #3b82f6;
            color: white;
            border: none;
            padding: 0.75rem 1.5rem;
            border-radius: 8px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s ease;
            white-space: nowrap;

            &:hover:not(:disabled) {
              background-color: #2563eb;
              transform: translateY(-1px);
              box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
            }

            &:disabled {
              opacity: 0.7;
              cursor: not-allowed;
            }
          }
        }
      }
    }

    .search-results {
      width: 100%;

      .character-card {
        background-color: #1f2937;
        border: 1px solid #374151;
        border-radius: 8px;
        padding: 16px;
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }

        .character-info {
          margin-bottom: 16px;

          h3 {
            color: #fff;
            font-size: 1.1rem;
            margin-bottom: 12px;
          }

          .info-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 12px;

            .info-item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              padding: 8px;
              background-color: rgba(55, 65, 81, 0.5);
              border-radius: 4px;

              span {
                color: #9ca3af;
                font-size: 0.875rem;
              }

              strong {
                color: #fff;
                font-weight: 500;

                &.banned {
                  color: #ef4444;
                }

                &.active {
                  color: #10b981;
                }
              }
            }
          }
        }

        .character-actions {
          display: flex;
          gap: 12px;
          align-items: center;

          .ban-controls {
            display: flex;
            gap: 12px;
            flex: 1;

            .ban-reason {
              background-color: #374151;
              border: 1px solid #4b5563;
              color: #fff;
              padding: 0.75rem 1rem;
              border-radius: 8px;
              font-size: 0.875rem;
              flex: 1;

              &:focus {
                outline: none;
                border-color: #ef4444;
                box-shadow: 0 0 0 2px rgba(239, 68, 68, 0.1);
              }

              &::placeholder {
                color: #6b7280;
              }
            }
          }

          .ban-btn {
            background-color: #dc2626;
            color: white;
            border: none;
            padding: 0.75rem 1.5rem;
            border-radius: 8px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s ease;
            white-space: nowrap;

            &:hover:not(:disabled) {
              background-color: #b91c1c;
              transform: translateY(-1px);
              box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
            }

            &:disabled {
              opacity: 0.7;
              cursor: not-allowed;
            }
          }

          .unban-btn {
            background-color: #059669;
            color: white;
            border: none;
            padding: 0.75rem 1.5rem;
            border-radius: 8px;
            font-weight: 500;
            cursor: pointer;
            transition: all 0.2s ease;
            width: 100%;
            white-space: nowrap;

            &:hover:not(:disabled) {
              background-color: #047857;
              transform: translateY(-1px);
              box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
            }

            &:disabled {
              opacity: 0.7;
              cursor: not-allowed;
            }
          }
        }
      }
    }
  }

  .status-message {
    margin-top: 16px;
    font-size: 0.875rem;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    
    &.error {
      background-color: rgba(220, 38, 38, 0.1);
      color: #ef4444;
      border: 1px solid rgba(220, 38, 38, 0.2);
    }
    
    &.success {
      background-color: rgba(5, 150, 105, 0.1);
      color: #10b981;
      border: 1px solid rgba(5, 150, 105, 0.2);
    }
  }
}

@media screen and (max-width: 767px) {
  .character-lookup-panel {
    .data-item {
      flex-direction: column;
      align-items: stretch;

      label {
        min-width: unset;
        margin-bottom: 12px;
      }

      .search-controls {
        form {
          .search-inputs {
            flex-direction: column;
          }
        }
      }

      .character-actions {
        flex-direction: column;

        .ban-controls {
          flex-direction: column;
        }
      }
    }
  }
}

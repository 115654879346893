.user-manager {
    padding: 2rem;
    color: #e4e6eb;
    
    .section-header {
      margin-bottom: 2rem;
      
      h2 {
        font-size: 2rem;
        font-weight: 600;
        color: #ffffff;
        margin-bottom: 0.5rem;
      }
      
      p {
        color: #b0b3b8;
        font-size: 0.95rem;
      }
    }
  
    .users-table {
      background: #1a1c23;
      border-radius: 8px;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      overflow: hidden;
      
      table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        
        thead {
          background: #242731;
          
          th {
            padding: 1rem 1.5rem;
            text-align: left;
            font-size: 0.75rem;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.05em;
            color: #9ca3af;
            border-bottom: 1px solid #2d303a;
            
            &:first-child {
              padding-left: 2rem;
            }
            
            &:last-child {
              padding-right: 2rem;
            }
          }
        }
        
        tbody {
          tr {
            transition: background-color 0.2s ease;
            
            &:hover {
              background: #242731;
            }
            
            td {
              padding: 1rem 1.5rem;
              color: #e4e6eb;
              border-bottom: 1px solid #2d303a;
              
              &:first-child {
                padding-left: 2rem;
              }
              
              &:last-child {
                padding-right: 2rem;
              }
              
              .user-icon {
                display: flex;
                align-items: center;
                gap: 0.75rem;
                
                svg {
                  color: #9ca3af;
                }
              }
            }
            
            &:last-child td {
              border-bottom: none;
            }
          }
        }
      }
    }
  
    .empty-state {
      padding: 3rem;
      text-align: center;
      color: #9ca3af;
    }
  
    .loading-state {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3rem;
      color: #9ca3af;
      
      svg {
        margin-right: 0.75rem;
        animation: spin 1s linear infinite;
      }
    }
  
    .error-state {
      margin: 2rem;
      padding: 1.5rem;
      background: rgba(239, 68, 68, 0.1);
      border-radius: 6px;
      color: #ef4444;
      
      p:first-child {
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  // Responsive styles
  @media (max-width: 768px) {
    .user-manager {
      padding: 1rem;
      
      .users-table {
        overflow-x: auto;
        
        table {
          thead th,
          tbody td {
            &:first-child {
              padding-left: 1rem;
            }
            
            &:last-child {
              padding-right: 1rem;
            }
          }
        }
      }
    }
  }
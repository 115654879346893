.endpoint-manager {
  min-height: 100vh;
  width: 100%;
  background-color: #0f172a;
  display: flex;
  flex-direction: column;

  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1.5rem;
    background-color: #1e293b;
    border-bottom: 1px solid #334155;

    .back-button {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      color: #94a3b8;
      font-size: 0.875rem;
      padding: 0.5rem 1rem;
      border-radius: 0.375rem;
      transition: all 0.2s;
      border: none;
      background: none;
      cursor: pointer;

      &:hover {
        background-color: #334155;
        color: #f8fafc;
      }
    }

    .controls {
      display: flex;
      gap: 0.75rem;

      button {
        padding: 0.5rem 1rem;
        border-radius: 0.375rem;
        font-size: 0.875rem;
        transition: all 0.2s;
        cursor: pointer;

        &.save-draft {
          color: #94a3b8;
          background-color: #1e293b;
          border: 1px solid #334155;

          &:hover {
            background-color: #334155;
          }
        }

        &.publish {
          color: white;
          background-color: #3b82f6;
          border: none;

          &:hover {
            background-color: #2563eb;
          }
        }

        &:disabled {
          opacity: 0.7;
          cursor: wait;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            right: -24px;
            top: 50%;
            transform: translateY(-50%);
            width: 16px;
            height: 16px;
            border: 2px solid transparent;
            border-top-color: currentColor;
            border-radius: 50%;
            animation: spin 0.8s linear infinite;
          }
        }
      }
    }
  }

  .editor-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%; // Remove max-width restriction
    margin: 0; // Remove margin
    padding: 1rem;

    .title-input {
      width: 100%;
      padding: 1rem;
      font-size: 2rem;
      font-weight: 600;
      border: none;
      background-color: transparent;
      color: #f8fafc;
      border-bottom: 2px solid #334155;
      margin-bottom: 1rem;
      direction: ltr !important;
      text-align: left !important;

      &::placeholder {
        color: #64748b;
      }

      &:focus {
        outline: none;
        border-color: #60a5fa;
      }
    }

    .toolbar {
      display: flex;
      gap: 0.5rem;
      padding: 0.75rem;
      background-color: #1e293b;
      border: 1px solid #334155;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      width: 100%;
      margin-bottom: 1rem;

      button {
        padding: 0.5rem;
        border-radius: 0.375rem;
        color: #94a3b8;
        transition: all 0.2s;
        border: none;
        background: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 32px;
        height: 32px;

        &:hover {
          background-color: #334155;
          color: #f8fafc;
        }

        &.active {
          background-color: #334155;
          color: #60a5fa;
        }
      }

      .separator {
        width: 1px;
        background-color: #334155;
        margin: 0 0.25rem;
      }

      .image-controls {
        display: flex;
        gap: 0.5rem;

        .file-upload {
          padding: 0.5rem;
          border-radius: 0.375rem;
          color: #94a3b8;
          transition: all 0.2s;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 32px;
          height: 32px;

          &:hover {
            background-color: #334155;
            color: #f8fafc;
          }
        }

        .existing-images {
          padding: 0.5rem;
          border-radius: 0.375rem;
          color: #94a3b8;
          transition: all 0.2s;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 32px;
          height: 32px;
          border: none;
          background: none;

          &:hover {
            background-color: #334155;
            color: #f8fafc;
          }
        }
      }

      .font-size-dropdown {
        position: relative;

        &:hover .font-size-options {
          display: flex;
        }

        .font-size-options {
          display: none;
          flex-direction: column;
          position: absolute;
          top: 100%;
          left: 0;
          background-color: #1e293b;
          border: 1px solid #334155;
          border-radius: 0.375rem;
          padding: 0.25rem;
          min-width: 120px;
          box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
          z-index: 10;
          margin-top: 0.5rem;

          button {
            width: 100%;
            justify-content: flex-start;
            padding: 0.5rem 1rem;
            height: auto;

            &:hover {
              background-color: #334155;
              color: #f8fafc;
            }
          }
        }
      }
    }

    .editor-content {
      flex: 1;
      width: 100%;
      padding: 1.5rem;
      background-color: #1e293b;
      border: 1px solid #334155;
      border-radius: 0.5rem;
      min-height: calc(100vh - 200px); // Adjust based on your header height
      color: #f8fafc;
      font-size: 1rem;
      line-height: 1.75;
      overflow-y: auto;
      position: relative;

      &[contenteditable=true]:empty:before {
        content: attr(placeholder);
        color: #64748b;
        pointer-events: none;
      }

      
      p {
        margin: 0.5rem 0;
        min-height: 1.5em;
        direction: ltr !important;
      }

      [style*="text-align: center"] {
        text-align: center !important;
      }

      [style*="text-align: right"] {
        text-align: right !important;
      }

      [style*="text-align: left"] {
        text-align: left !important;
      }


      img {
        width: calc((100% - 2.5rem) / 6) !important; // 6 images per row
        height: auto;
        max-height: 120px; // Reduced height for smaller images
        object-fit: cover;
        display: inline-block;
        margin: 0.25rem !important; // Keep small margin for spacing
        border-radius: 0.25rem;
        border: 1px solid #334155;
        vertical-align: top;
      }

      a {
        color: #60a5fa;
        text-decoration: underline;
        
        &:hover {
          color: #93c5fd;
        }
      }

      ul, ol {
        margin: 1rem 0;
        padding-left: 1.5rem;
      }

      li {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &[contenteditable=true]:empty:before {
        content: attr(placeholder);
        color: #64748b;
        pointer-events: none;
      }

      &:focus {
        outline: none;
        border-color: #60a5fa;
      }
    }
  }
}



.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .modal-content {
    // Update modal content max-width to be wider
    background-color: #1e293b;
    border-radius: 0.5rem;
    padding: 1.5rem;
    max-width: 1200px; // Increased from 800px
    width: 95%;
    max-height: 90vh;
    overflow-y: auto;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid #334155;

      h2 {
        color: #f8fafc;
        margin: 0;
      }

      .close-button {
        background: none;
        border: none;
        color: #94a3b8;
        cursor: pointer;
        padding: 0.5rem;
        border-radius: 0.375rem;
        transition: all 0.2s;

        &:hover {
          background-color: #334155;
          color: #f8fafc;
        }
      }
    }

    .image-grid, .image-preview-grid {
      display: grid;
      grid-template-columns: repeat(6, 1fr); // 6 columns
      gap: 0.5rem;
      margin: 1rem 0;

      .image-item, .preview-item {
        aspect-ratio: 1;
        height: 120px; // Match editor image height

        &:hover {
          border-color: #60a5fa;
          transform: scale(1.05); // Added subtle zoom effect on hover
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .image-info {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          background-color: rgba(0, 0, 0, 0.75);
          padding: 0.25rem;
          font-size: 0.625rem; // Smaller font size
          color: #f8fafc;
          text-align: center;
          transform: translateY(100%);
          transition: transform 0.2s;
          
          .image-date {
            font-size: 0.625rem;
            line-height: 1;
          }
          
          .image-modifier {
            font-size: 0.5rem;
            opacity: 0.8;
          }
        }

        &:hover .image-info {
          transform: translateY(0);
        }
      }
    }

    .modal-footer {
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 1rem;
      padding-top: 1rem;
      border-top: 1px solid #334155;

      button {
        padding: 0.5rem 1rem;
        border-radius: 0.375rem;
        font-size: 0.875rem;
        transition: all 0.2s;
        cursor: pointer;

        &.cancel-button {
          background-color: transparent;
          border: 1px solid #334155;
          color: #94a3b8;

          &:hover {
            background-color: #334155;
            color: #f8fafc;
          }
        }

        &.confirm-button {
          background-color: #3b82f6;
          border: none;
          color: white;

          &:hover {
            background-color: #2563eb;
          }
        }
      }
    }
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  color: #f8fafc;

  .spinner {
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }
}

.error {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #ef4444;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  animation: slideIn 0.2s ease-out;
  z-index: 50;
}

.success {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: #22c55e;
  color: white;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1);
  animation: slideIn 0.2s ease-out;
  z-index: 50;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.strategic-login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #111827;

  .login-container {
    background-color: #1f2937;
    padding: 2.5rem;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    width: 100%;
    max-width: 400px;

    h1 {
      color: #fff;
      margin-bottom: 2rem;
      text-align: center;
      font-size: 2rem;
      font-weight: 600;
    }

    .error-message {
      background-color: rgba(220, 38, 38, 0.2);
      color: #ef4444;
      padding: 0.75rem;
      border-radius: 6px;
      margin-bottom: 1rem;
      text-align: center;
      border: 1px solid rgba(220, 38, 38, 0.3);
    }

    .verification-message {
      background-color: rgba(59, 130, 246, 0.2);
      color: #60a5fa;
      padding: 0.75rem;
      border-radius: 6px;
      margin-bottom: 1.5rem;
      text-align: center;
      border: 1px solid rgba(59, 130, 246, 0.3);
    }

    .form-group {
      margin-bottom: 1.5rem;

      label {
        display: block;
        margin-bottom: 0.5rem;
        color: #9ca3af;
        font-weight: 500;
      }

      input {
        width: 100%;
        padding: 0.75rem;
        background-color: #111827;
        border: 1px solid #374151;
        border-radius: 6px;
        font-size: 1rem;
        color: #fff;
        transition: all 0.3s ease;

        &:focus {
          outline: none;
          border-color: #3b82f6;
          box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
        }

        &::placeholder {
          color: #6b7280;
        }

        &#verificationCode {
          letter-spacing: 0.25em;
          font-size: 1.25rem;
          text-align: center;
          
          &::placeholder {
            letter-spacing: normal;
            font-size: 1rem;
          }
        }
      }
    }

    button {
      width: 100%;
      padding: 0.875rem;
      background-color: #3b82f6;
      color: white;
      border: none;
      border-radius: 6px;
      font-size: 1rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background-color: #2563eb;
        transform: translateY(-1px);
      }

      &:active {
        transform: translateY(0);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.3);
      }
    }
  }
}

.subcategory-manager {
  padding: 2rem;
  background-color: #f8fafc;
  min-height: 100vh;
  transition: all 0.2s ease;

  // Dark mode styles
  .dark & {
    background-color: #1e293b;
  }

  .subcategory-header {
    margin-bottom: 2rem;

    .back-button {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      color: #64748b;
      font-weight: 500;
      transition: all 0.2s ease;
      background-color: white;
      border: 1px solid #e2e8f0;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

      &:hover {
        color: #1e293b;
        background-color: #f1f5f9;
      }

      // Dark mode
      .dark & {
        background-color: #334155;
        border-color: #475569;
        color: #94a3b8;

        &:hover {
          color: #f1f5f9;
          background-color: #475569;
        }
      }
    }

    h2 {
      margin-top: 1rem;
      font-size: 1.5rem;
      font-weight: 600;
      color: #1e293b;

      // Dark mode
      .dark & {
        color: #f1f5f9;
      }
    }
  }

  .add-subcategory-form {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.75rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;

    // Dark mode
    .dark & {
      background-color: #1e293b;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    }

    .form-group {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      input[type="text"] {
        padding: 0.75rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.5rem;
        font-size: 0.875rem;
        transition: all 0.2s ease;
        background-color: white;
        color: #1e293b;

        &:focus {
          outline: none;
          border-color: #6366f1;
          box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
        }

        &::placeholder {
          color: #94a3b8;
        }

        // Dark mode
        .dark & {
          background-color: #334155;
          border-color: #475569;
          color: #f1f5f9;

          &::placeholder {
            color: #64748b;
          }

          &:focus {
            border-color: #818cf8;
            box-shadow: 0 0 0 3px rgba(129, 140, 248, 0.1);
          }
        }
      }
    }

    .image-upload {
      display: flex;
      align-items: center;
      gap: 1rem;

      .upload-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1rem;
        background-color: #f8fafc;
        border: 1px dashed #cbd5e1;
        border-radius: 0.5rem;
        color: #64748b;
        font-size: 0.875rem;
        transition: all 0.2s ease;
        cursor: pointer;

        &:hover {
          background-color: #f1f5f9;
          border-color: #94a3b8;
        }

        // Dark mode
        .dark & {
          background-color: #334155;
          border-color: #475569;
          color: #94a3b8;

          &:hover {
            background-color: #475569;
            border-color: #64748b;
          }
        }
      }

      .image-preview {
        margin-top: 0.75rem;
        width: 80px; // Changed to match CategoryManager
        height: 80px; // Changed to match CategoryManager
        border-radius: 0.375rem;
        overflow: hidden;
        border: 2px solid #e2e8f0;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover; // Changed from contain to match CategoryManager
        }

        // Dark mode
        .dark & {
          border-color: #475569;
        }
      }
    }

    .add-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      padding: 0.75rem;
      background-color: #6366f1;
      color: white;
      border-radius: 0.5rem;
      font-weight: 500;
      transition: all 0.2s ease;

      &:hover:not(:disabled) {
        background-color: #4f46e5;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      // Dark mode
      .dark & {
        background-color: #818cf8;

        &:hover:not(:disabled) {
          background-color: #6366f1;
        }
      }
    }
  }

  .subcategories-list {
    display: grid;
    gap: 1rem;

    .subcategory-item {
      background-color: white;
      border-radius: 0.75rem;
      padding: 1.25rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      transition: all 0.2s ease;

      &:hover {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        transform: translateY(-1px);
      }

      // Dark mode
      .dark & {
        background-color: #1e293b;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);

        &:hover {
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
        }
      }

      .subcategory-info {
        display: flex;
        gap: 1.25rem;
        flex: 1;

        .subcategory-icon {
          width: 100px; // Changed from 64px to match CategoryManager
          height: 100px; // Changed from 64px to match CategoryManager
          flex-shrink: 0;
          border-radius: 1rem; // Changed to match CategoryManager
          overflow: hidden;
          border: 1px solid #e2e8f0; // Changed to be more subtle like CategoryManager

          img {
            width: 100%;
            height: 100%;
            object-fit: cover; // Changed from contain to match CategoryManager
          }

          // Dark mode
          .dark & {
            border-color: #475569;
          }
        }

        .subcategory-text {
          flex: 1;

          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: #1e293b;
            margin-bottom: 0.25rem; // Added to match CategoryManager

            // Dark mode
            .dark & {
              color: #f1f5f9;
            }
          }

          p {
            color: #64748b;
            font-size: 0.875rem;
            margin-bottom: 0.5rem; // Changed from 0.75rem to match CategoryManager

            // Dark mode
            .dark & {
              color: #94a3b8;
            }
          }

          .metadata {
            display: flex;
            flex-direction: column;
            gap: 0.25rem; // Changed from 0.5rem to match CategoryManager
            color: #94a3b8;

            .flex {
              display: flex;
              align-items: center;

              svg {
                color: #6366f1; // Added to match CategoryManager's icon color
              }
            }

            // Dark mode
            .dark & {
              color: #64748b;

              svg {
                color: #818cf8;
              }
            }
          }
        }
      }

      .actions {
        display: flex;
        gap: 0.5rem;

        button {
          padding: 0.5rem;
          border-radius: 0.375rem;
          color: #64748b;
          transition: all 0.2s ease;

          // Dark mode
          .dark & {
            color: #94a3b8;
          }

          &.edit {
            &:hover {
              background-color: #e0f2fe;
              color: #0284c7;

              // Dark mode
              .dark & {
                background-color: rgba(14, 165, 233, 0.2);
                color: #38bdf8;
              }
            }
          }

          &.delete {
            &:hover {
              background-color: #fee2e2;
              color: #dc2626;

              // Dark mode
              .dark & {
                background-color: rgba(239, 68, 68, 0.2);
                color: #f87171;
              }
            }
          }
        }
      }
    }
  }

  .edit-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    input[type="text"] {
      padding: 0.75rem;
      border: 1px solid #e2e8f0;
      border-radius: 0.5rem;
      font-size: 0.875rem;
      background-color: white;
      color: #1e293b;

      &:focus {
        outline: none;
        border-color: #6366f1;
        box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.1);
      }

      // Dark mode
      .dark & {
        background-color: #334155;
        border-color: #475569;
        color: #f1f5f9;

        &:focus {
          border-color: #818cf8;
        }
      }
    }

    .actions {
      display: flex;
      gap: 0.5rem;
      justify-content: flex-end;

      button {
        padding: 0.5rem;
        border-radius: 0.375rem;
        transition: all 0.2s ease;

        &:first-child {
          color: #dc2626;

          &:hover {
            background-color: #fee2e2;
          }

          // Dark mode
          .dark & {
            color: #f87171;

            &:hover {
              background-color: rgba(239, 68, 68, 0.2);
            }
          }
        }

        &:last-child {
          color: #16a34a;

          &:hover {
            background-color: #dcfce7;
          }

          // Dark mode
          .dark & {
            color: #4ade80;

            &:hover {
              background-color: rgba(34, 197, 94, 0.2);
            }
          }
        }
      }
    }
  }
}

// Loading spinner
.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  gap: 1rem;
  color: #64748b;

  // Dark mode
  .dark & {
    color: #94a3b8;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #e2e8f0;
    border-top-color: #6366f1;
    border-radius: 50%;
    animation: spin 1s linear infinite;

    // Dark mode
    .dark & {
      border-color: #475569;
      border-top-color: #818cf8;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Error message
.error {
  background-color: #fee2e2;
  color: #dc2626;
  border-radius: 0.5rem;

  // Dark mode
  .dark & {
    background-color: rgba(239, 68, 68, 0.2);
    color: #f87171;
  }
}

// Add new styles for image gallery
.subcategory-image-gallery {
  .image-upload-section {
    margin-bottom: 1.5rem;
  }

  .images-grid {
    .image-item {
      position: relative;
      cursor: pointer;
      transition: transform 0.2s ease;

      &:hover {
        transform: scale(1.02);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.5rem;
        border: 1px solid #e2e8f0;

        // Dark mode
        .dark & {
          border-color: #475569;
        }
      }

      button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        padding: 0.375rem;
        border-radius: 9999px;
        background-color: #ef4444;
        color: white;
        opacity: 0;
        transition: opacity 0.2s ease;

        &:hover {
          background-color: #dc2626;
        }

        // Dark mode
        .dark & {
          background-color: #f87171;

          &:hover {
            background-color: #ef4444;
          }
        }
      }

      &:hover button {
        opacity: 1;
      }
    }
  }

  // Empty state
  .no-images {
    text-align: center;
    padding: 2rem;
    background-color: #f8fafc;
    border: 2px dashed #e2e8f0;
    border-radius: 0.75rem;
    color: #64748b;

    svg {
      margin: 0 auto 1rem;
      opacity: 0.5;
    }

    // Dark mode
    .dark & {
      background-color: #1e293b;
      border-color: #475569;
      color: #94a3b8;
    }
  }

  // Image preview modal
  .image-preview-modal {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 60;

    .preview-content {
      position: relative;
      max-width: 90vw;
      max-height: 90vh;

      img {
        max-width: 100%;
        max-height: 90vh;
        object-fit: contain;
      }

      .close-button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        padding: 0.5rem;
        background-color: rgba(255, 255, 255, 0.1);
        color: white;
        border-radius: 9999px;
        transition: background-color 0.2s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
}

// Upload button in image gallery
.upload-section {
  .upload-button {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.75rem 1rem;
    background-color: #3b82f6;
    color: white;
    border-radius: 0.5rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover:not(:disabled) {
      background-color: #2563eb;
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }

    // Dark mode
    .dark & {
      background-color: #60a5fa;

      &:hover:not(:disabled) {
        background-color: #3b82f6;
      }
    }
  }
}

// Additional utility classes
.hidden {
  display: none;
}

.max-w-4xl {
  max-width: 56rem;
}

.w-full {
  width: 100%;
}

.overflow-hidden {
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.inset-0 {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.z-50 {
  z-index: 50;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.bg-black\/50 {
  background-color: rgba(0, 0, 0, 0.5);
}

.p-4 {
  padding: 1rem;
}

.bg-white {
  background-color: white;

  .dark & {
    background-color: #1e293b;
  }
}

.rounded-lg {
  border-radius: 0.5rem;
}

.border-b {
  border-bottom-width: 1px;
  border-bottom-color: #e2e8f0;

  .dark & {
    border-bottom-color: #475569;
  }
}

.text-lg {
  font-size: 1.125rem;
}

.font-semibold {
  font-weight: 600;
}

.gap-2 {
  gap: 0.5rem;
}

.transition-colors {
  transition-property: background-color, border-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease;
}

.hover\:bg-gray-100:hover {
  background-color: #f3f4f6;

  .dark & {
    background-color: #334155;
  }
}

// Additional animations
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate-fade-in {
  animation: fadeIn 0.2s ease-out;
}

// Image gallery popup styles
.subcategory-image-gallery {
  position: fixed;
  inset: 0;
  background-color: rgba(15, 23, 42, 0.95); // Darker overlay
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  z-index: 50;

  .bg-white {
    background-color: #1e293b; // Dark background
    border: 1px solid #334155;
    border-radius: 0.75rem;
    max-width: 56rem;
    width: 100%;
    max-height: 90vh;
    overflow: hidden;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.3);
  }

  .p-4.border-b {
    border-bottom: 1px solid #334155;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-lg.font-semibold {
    color: #f8fafc; // Light text for dark background
  }

  .upload-section {
    margin: 1rem 0;

    .upload-button {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.75rem 1rem;
      background-color: #3b82f6;
      color: white;
      border-radius: 0.5rem;
      font-weight: 500;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover:not(:disabled) {
        background-color: #2563eb;
      }

      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .images-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
    padding: 1rem;

    .image-item {
      position: relative;
      aspect-ratio: 1;
      background-color: #1e293b;
      border: 1px solid #334155;
      border-radius: 0.5rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      &:hover .delete-button {
        opacity: 1;
      }

      .delete-button {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        background-color: #ef4444;
        color: white;
        padding: 0.375rem;
        border-radius: 9999px;
        opacity: 0;
        transition: all 0.2s ease;

        &:hover {
          background-color: #dc2626;
        }
      }
    }
  }

  .no-images {
    text-align: center;
    padding: 3rem;
    color: #94a3b8;
    background-color: #1e293b;
    border: 2px dashed #334155;
    border-radius: 0.75rem;
    margin: 1rem;

    svg {
      margin: 0 auto 1rem;
      opacity: 0.5;
    }
  }

  // Close button
  button.p-2 {
    color: #94a3b8;
    border-radius: 9999px;
    transition: all 0.2s ease;

    &:hover {
      background-color: #334155;
      color: #f8fafc;
    }
  }
}

  // Image Gallery Popup
  .fixed.inset-0.bg-black\/50 {
    background-color: rgba(15, 23, 42, 0.98);
    z-index: 50;
    padding: 2rem;

    .bg-white {
      background-color: white;
      border: 1px solid #e2e8f0;
      border-radius: 1rem;
      box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      max-width: 80rem; // Increased from 56rem
      width: 95%;
      max-height: 85vh;
      margin: 0 auto;

      &.dark, .dark & {
        background-color: #1e293b !important;
        border-color: #334155;
      }

      // Header section
      .border-b {
        border-color: #e2e8f0;
        padding: 1.5rem 2rem;
        margin-bottom: 1rem;

        &.dark, .dark & {
          border-color: #334155;
        }

        // Title
        .text-lg {
          font-size: 1.5rem;
          font-weight: 600;
          color: #1e293b;

          &.dark, .dark & {
            color: #f8fafc !important;
          }
        }

        // Close button
        button.hover\:bg-gray-100 {
          padding: 0.75rem;
          color: #64748b;
          border-radius: 0.75rem;
          transition: all 0.2s ease;

          &:hover {
            background-color: #f3f4f6;
            transform: scale(1.05);
          }

          &.dark, .dark & {
            color: #94a3b8;

            &:hover {
              background-color: #334155;
            }
          }
        }
      }

      // Content area
      .p-4 {
        padding: 2rem;

        // Upload section
        .upload-section {
          margin-bottom: 2rem;

          .bg-blue-500 {
            background-color: #3b82f6;
            padding: 0.875rem 1.5rem;
            font-weight: 500;
            border-radius: 0.75rem;
            transition: all 0.2s ease;
            
            &:hover {
              background-color: #2563eb;
              transform: translateY(-1px);
              box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            }

            &.dark, .dark & {
              background-color: #60a5fa;

              &:hover {
                background-color: #3b82f6;
              }
            }

            // Upload icon
            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }

        // Images grid
        .grid {
          grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
          gap: 1.5rem;
          padding: 0.5rem;

          .group {
            background-color: #f8fafc;
            border-radius: 1rem;
            overflow: hidden;
            transition: all 0.2s ease;
            
            &:hover {
              transform: translateY(-2px);
              box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            }

            &.dark, .dark & {
              background-color: #1e293b;

              &:hover {
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
              }
            }

            img {
              aspect-ratio: 1;
              object-fit: cover;
            }

            // Delete button
            .bg-red-500 {
              padding: 0.625rem;
              opacity: 0;
              transform: scale(0.9);
              transition: all 0.2s ease;

              &.dark, .dark & {
                background-color: #f87171;

                &:hover {
                  background-color: #ef4444;
                }
              }
            }

            &:hover .bg-red-500 {
              opacity: 1;
              transform: scale(1);
            }
          }
        }

        // Empty state
        .text-center {
          padding: 4rem 2rem;
          border-radius: 1rem;
          border: 2px dashed #e2e8f0;
          background-color: #f8fafc;

          &.dark, .dark & {
            border-color: #334155;
            background-color: #1e293b;
          }

          .text-gray-500 {
            color: #6b7280;
            font-size: 1.125rem;
            
            &.dark, .dark & {
              color: #94a3b8;
            }
          }

          svg {
            width: 4rem;
            height: 4rem;
            margin: 0 auto 1.5rem;
            opacity: 0.4;
          }
        }
      }
    }
  }

  // Image preview modal
  .fixed.inset-0.bg-black\/80 {
    padding: 2rem;
    
    .max-w-\[90vw\] {
      img {
        border-radius: 0.5rem;
        box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
      }

      .bg-white\/10 {
        padding: 0.75rem;
        transition: all 0.2s ease;

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
          transform: scale(1.05);
        }

        &.dark, .dark & {
          background-color: rgba(255, 255, 255, 0.1);

          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }
  }
  .subcategory-manager {
    .category-icon {
      width: 80px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.5rem;
      overflow: hidden;
      background: rgba(255, 255, 255, 0.03);
      border: 1px solid rgba(255, 255, 255, 0.1);
      transition: all 0.3s ease;
  
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  
    .subcategory-item {
      background: rgba(255, 255, 255, 0.03);
      border: 1px solid rgba(255, 255, 255, 0.1);
      border-radius: 1rem;
      margin-bottom: 1rem;
      overflow: hidden;
      transition: all 0.3s ease;
  
      &:hover {
        transform: translateY(-2px);
        border-color: rgba(96, 165, 250, 0.3);
        background: rgba(255, 255, 255, 0.05);
      }
  
      .subcategory-info {
        display: flex;
        padding: 1rem;
        gap: 1rem;
        align-items: center;
      }
  
      .subcategory-content {
        flex: 1;
      }
  
      .actions {
        display: flex;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        background: rgba(255, 255, 255, 0.02);
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }
    }
  
    .edit-form {
      padding: 1rem;
      background: rgba(255, 255, 255, 0.05);
      border-radius: 0.5rem;
  
      .form-content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
  
      .input-field {
        width: 100%;
        padding: 0.5rem;
        border-radius: 0.375rem;
        border: 1px solid rgba(255, 255, 255, 0.1);
        background: rgba(255, 255, 255, 0.05);
        color: #fff;
        
        &:focus {
          border-color: rgba(96, 165, 250, 0.5);
          outline: none;
        }
      }
  
      .upload-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.5rem 1rem;
        background: rgba(96, 165, 250, 0.1);
        color: #60A5FA;
        border-radius: 0.375rem;
        cursor: pointer;
        transition: all 0.2s ease;
  
        &:hover {
          background: rgba(96, 165, 250, 0.2);
        }
      }
  
      .actions {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
        margin-top: 1rem;
      }
    }
  }
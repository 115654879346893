.wiki-settings {
  padding: 1.5rem;

  .section-header {
    margin-bottom: 2rem;
    
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: #1f2937;
    }
  }

  .settings-grid {
    display: grid;
    gap: 2rem;
    max-width: 800px;

    .setting-item {
      background-color: white;
      padding: 1.5rem;
      border-radius: 0.5rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

      .setting-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0.5rem;

        label {
          font-weight: 500;
          color: #1f2937;
          font-size: 1rem;
        }

        svg {
          color: #6b7280;
        }
      }

      &.password-change {
        form {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          .form-group {
            input {
              width: 100%;
              padding: 0.75rem;
              border: 1px solid #e5e7eb;
              border-radius: 0.375rem;
              font-size: 0.875rem;
              transition: all 0.2s ease;

              &:focus {
                outline: none;
                border-color: #4f46e5;
                box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
              }

              &::placeholder {
                color: #9ca3af;
              }
            }
          }

          .save-button {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.75rem 1.5rem;
            background-color: #4f46e5;
            color: white;
            border-radius: 0.375rem;
            font-weight: 500;
            transition: all 0.2s ease;
            align-self: flex-start;
            margin-top: 0.5rem;

            &:hover {
              background-color: #4338ca;
            }

            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
  }

  // Messages
  .error-message, .success-message {
    padding: 0.75rem;
    border-radius: 0.375rem;
    margin-bottom: 1rem;
    font-size: 0.875rem;
  }

  .error-message {
    background-color: #fee2e2;
    color: #dc2626;
  }

  .success-message {
    background-color: #dcfce7;
    color: #16a34a;
  }
}
/* Main container styles */
.clan-ranking {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #0B1221;
  min-height: 100vh;
}

.clan-ranking-content {
  padding: 2rem 1rem;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 1rem 0.5rem;
  }
}

/* Header styles */
.clan-ranking-header {
  text-align: center;
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
  }
}

/* Countdown styles */
.countdown-container {
  margin-bottom: 3rem;
  background: linear-gradient(180deg, rgba(19, 30, 50, 0.6) 0%, rgba(19, 30, 50, 0.8) 100%);
  padding: 2rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1rem;
    margin-bottom: 2rem;
  }
}

.countdown-clock {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    gap: 1rem;
  }
}

.clock-segment {
  background: linear-gradient(145deg, #162543, #131E32);
  padding: 1.5rem;
  border-radius: 1rem;
  min-width: 100px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease;

  &:hover {
    transform: translateY(-2px);
  }

  @media (max-width: 768px) {
    min-width: 70px;
    padding: 1rem;
  }
}

.clock-number {
  font-size: 2.75rem;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
}

.clock-label {
  font-size: 1.1rem;
  color: #6497c2;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }
}

/* Table styles */
.table-wrapper {
  background: rgba(19, 30, 50, 0.6);
  border-radius: 1rem;
  padding: 1rem;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
  @media (max-width: 768px) {
    padding: 0.5rem;
    border-radius: 0.5rem;
  }
}

.table-scroll {
  overflow-x: auto;
  border-radius: 0.5rem;
  -webkit-overflow-scrolling: touch;
  background: rgba(11, 18, 33, 0.6);
  
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(31, 41, 55, 0.5);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #374151;
    border-radius: 4px;
    
    &:hover {
      background: #4b5563;
    }
  }
}

table {
  width: 100%;
  min-width: 960px;
  border-collapse: separate;
  border-spacing: 0;
  background-color: transparent;

  th, td {
    padding: 1rem;
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid rgba(27, 44, 78, 0.6);
    transition: background-color 0.2s ease;

    .rank-icon {
      width: 40px;
      height: 40px;
      vertical-align: middle;
      margin-left: 8px;
    }
  }

  th {
    background-color: rgba(13, 29, 52, 0.95);
    color: #ffffff;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 10;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  tr {
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba(35, 79, 125, 0.8);
      transform: translateX(4px);
    }

    &.row-even {
      background-color: rgba(26, 58, 102, 0.4);
    }

    &.row-odd {
      background-color: rgba(15, 45, 82, 0.4);
    }
  }

  td {
    color: #e5e7eb;
    font-size: 1rem;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }
}

/* Clan members styles */
.clan-members-container {
  width: 100%;
  background-color: transparent;
  padding: 1rem;
  margin-top: 1rem;

  .back-button {
    background: linear-gradient(145deg, #1f2937, #1a2433);
    color: #ffffff;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    margin-bottom: 1.5rem;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      background: linear-gradient(145deg, #374151, #2d3748);
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    }

    @media (max-width: 768px) {
      padding: 0.5rem 1rem;
      font-size: 0.9rem;
    }
  }
}

/* Print styles */
@media print {
  .clan-ranking {
    background-color: white;
    color: black;
  }

  .countdown-container,
  .back-button {
    display: none;
  }

  table {
    border: 1px solid #ddd;

    th, td {
      border: 1px solid #ddd;
      color: black;
      background-color: white !important;
    }

    th {
      background-color: #f5f5f5 !important;
    }

    tr:hover {
      background-color: transparent !important;
      transform: none !important;
    }
  }
}

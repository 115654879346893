.tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;

  button {
    background-color: #111827;
    color: #9ca3af;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.2s ease;

    &:hover {
      background-color: #1a2234;
      color: #fff;
    }

    &.active {
      background-color: #3b82f6;
      color: #fff;
    }
  }
}

.title-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .title {
    font-size: 1.75rem;
    font-weight: 600;
  }

  .username {
    font-size: 1rem;
    color: #9ca3af;
  }
}

.maintenance-panel,
.characters-panel,
.items-panel {
  .data-item {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #111827;
    border-radius: 8px;
    transition: all 0.2s ease;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #1a2234;
    }

    label {
      font-weight: 600;
      color: #9ca3af;
      min-width: 160px;
      flex-shrink: 0;
    }

    .server-time {
      font-family: monospace;
      font-size: 1.1rem;
      color: #60a5fa;
      background-color: rgba(59, 130, 246, 0.1);
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid rgba(59, 130, 246, 0.2);
      flex: 1;
      text-align: center;
      letter-spacing: 0.5px;
    }

    .maintenance-status {
      display: flex;
      flex-direction: column;
      gap: 16px;
      flex: 1;

      .status-indicator {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 12px 16px;
        border-radius: 8px;
        font-weight: 500;

        &.enabled {
          background-color: rgba(220, 38, 38, 0.1);
          color: #ef4444;
          border: 1px solid rgba(220, 38, 38, 0.2);

          &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #ef4444;
            animation: pulse 2s infinite;
          }
        }

        &.disabled {
          background-color: rgba(5, 150, 105, 0.1);
          color: #10b981;
          border: 1px solid rgba(5, 150, 105, 0.2);

          &::before {
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background-color: #10b981;
          }
        }
      }

      .toggle-container {
        display: flex;
        gap: 12px;
        align-items: center;

        .toggle-btn {
          background-color: #374151;
          color: #9ca3af;
          border: none;
          padding: 0.75rem 1.5rem;
          border-radius: 8px;
          cursor: pointer;
          transition: all 0.2s ease;
          font-weight: 500;
          min-width: 120px;

          &.active {
            background-color: #dc2626;
            color: #fff;
          }

          &:hover:not(:disabled) {
            transform: translateY(-1px);
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
          }

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
    }

    .schedule-controls {
      flex: 1;

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .schedule-inputs {
          display: grid;
          grid-template-columns: 1fr 1fr;
          gap: 20px;

          .input-group {
            display: flex;
            flex-direction: column;
            gap: 8px;

            label {
              font-size: 0.875rem;
              color: #9ca3af;
              font-weight: 500;
              min-width: unset;
            }

            input[type="datetime-local"] {
              background-color: #1f2937;
              border: 1px solid #374151;
              color: #fff;
              padding: 0.75rem 1rem;
              border-radius: 8px;
              font-size: 0.875rem;
              transition: all 0.2s ease;

              &:focus {
                outline: none;
                border-color: #3b82f6;
                box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
              }

              &::-webkit-calendar-picker-indicator {
                filter: invert(1);
                opacity: 0.5;
                cursor: pointer;

                &:hover {
                  opacity: 0.8;
                }
              }
            }
          }
        }

        .schedule-btn {
          background-color: #3b82f6;
          color: white;
          border: none;
          padding: 0.75rem 1.5rem;
          border-radius: 8px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;
          align-self: flex-start;

          &:hover:not(:disabled) {
            background-color: #2563eb;
            transform: translateY(-1px);
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
          }

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
    }

    .scheduled-info {
      flex: 1;
      background-color: #1f2937;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #374151;

      .schedule-details {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 16px;

        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          color: #9ca3af;
          font-size: 0.875rem;

          strong {
            color: #fff;
            font-weight: 500;
          }

          .status {
            display: inline-flex;
            align-items: center;
            gap: 6px;
            padding: 4px 8px;
            border-radius: 4px;
            font-size: 0.75rem;
            font-weight: 500;
            text-transform: uppercase;
            letter-spacing: 0.05em;

            &.scheduled {
              background-color: rgba(59, 130, 246, 0.1);
              color: #60a5fa;
            }

            &.in_progress {
              background-color: rgba(245, 158, 11, 0.1);
              color: #fbbf24;
            }
          }
        }
      }

      .cancel-btn {
        background-color: #dc2626;
        color: white;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 8px;
        font-weight: 500;
        cursor: pointer;
        transition: all 0.2s ease;
        width: 100%;

        &:hover:not(:disabled) {
          background-color: #b91c1c;
          transform: translateY(-1px);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }

  .status-message {
    margin-top: 16px;
    font-size: 0.875rem;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    
    &.error {
      background-color: rgba(220, 38, 38, 0.1);
      color: #ef4444;
      border: 1px solid rgba(220, 38, 38, 0.2);
    }
    
    &.success {
      background-color: rgba(5, 150, 105, 0.1);
      color: #10b981;
      border: 1px solid rgba(5, 150, 105, 0.2);
    }
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@media screen and (max-width: 767px) {
  .tabs {
    flex-direction: column;
    gap: 0.5rem;
  }

  .data-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    label {
      min-width: unset;
    }

    .schedule-controls {
      form {
        .schedule-inputs {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .title-section {
    .title {
      font-size: 1.5rem;
    }

    .username {
      font-size: 0.875rem;
    }
  }
}

$fontFamily: "Arial", sans-serif;
$colorBackground: #111827;
$colorItem: #1f2937;
$colorItemHover: #374151;
$colorText: #f0f0f0;
$colorNew: #48bb78;
$colorBug: #f5b965;
$colorBorder: rgba(255, 255, 255, 0.1);
$colorShadow: rgba(0, 0, 0, 0.3);

.patch-notes-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: $colorBackground;
  background-image: linear-gradient(to bottom, darken($colorBackground, 3%), $colorBackground);
  padding: 2rem;
  min-height: 100vh;
  box-sizing: border-box;
}

.patch-notes-list {
  width: 100%;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.patch-notes-item {
  background-color: $colorItem;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.5);
  }

  .patch-notes-thumbnail {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; // 16:9 aspect ratio
    overflow: hidden;
    background-color: darken($colorItem, 5%);

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 70%;
      background: linear-gradient(
        to bottom,
        transparent,
        rgba(0, 0, 0, 0.7) 50%,
        rgba(0, 0, 0, 0.9)
      );
      pointer-events: none;
    }

    .expanded-thumbnail {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }

    .patch-notes-header {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 2rem;
      z-index: 1;
      cursor: pointer;
      box-sizing: border-box;

      .version-info {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        h3 {
          margin: 0;
          font-size: 2rem;
          color: $colorText;
          font-weight: 700;
          letter-spacing: -0.02em;
          text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
        }

        .date {
          color: rgba($colorText, 0.8);
          font-size: 1rem;
          font-weight: 500;
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
        }
      }

      .toggle-icon {
        position: absolute;
        right: 2rem;
        bottom: 2rem;
        font-size: 1.25rem;
        color: rgba($colorText, 0.8);
        transition: all 0.2s ease;
        padding: 0.75rem;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.3);
        backdrop-filter: blur(4px);

        &:hover {
          color: $colorText;
          background: rgba(0, 0, 0, 0.5);
          transform: scale(1.1);
        }
      }
    }
  }

  &:hover {
    .expanded-thumbnail {
      transform: scale(1.05);
    }
  }

  .patch-notes-content {
    background: linear-gradient(to bottom, 
      darken($colorItem, 2%),
      $colorItem
    );

    .notes-sections {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      gap: 2.5rem;
    }
  }
}

.notes-section {
  .section-header {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid $colorBorder;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 50px;
      height: 2px;
      background: currentColor;
      border-radius: 2px;
    }

    .icon {
      font-size: 1.4rem;
    }

    h4 {
      margin: 0;
      font-size: 1.2rem;
      font-weight: 600;
      color: $colorText;
      letter-spacing: 0.01em;
    }
  }

  &.new-section {
    .icon, .section-header::after {
      color: $colorNew;
    }
  }

  &.bug-section {
    .icon, .section-header::after {
      color: $colorBug;
    }
  }

  ul {
    list-style: none;
    padding: 0 0.5rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .note {
      display: flex;
      align-items: flex-start;
      gap: 0.75rem;
      line-height: 1.6;
      color: rgba($colorText, 0.9);
      font-size: 1rem;
      padding: 0.5rem 0;
      transition: transform 0.2s ease;

      &:hover {
        transform: translateX(4px);
      }

      .note-bullet {
        color: rgba($colorText, 0.3);
        font-size: 1.2em;
        line-height: 1;
        margin-top: 0.2rem;
      }

      .note-text {
        flex: 1;
      }

      &.new .note-text {
        color: rgba($colorNew, 0.9);
      }

      &.bug .note-text {
        color: rgba($colorBug, 0.9);
      }
    }
  }
}

@media (min-width: 768px) {
  .patch-notes-container {
    padding: 3rem;
  }

  .patch-notes-header {
    .version-info h3 {
      font-size: 1.8rem;
    }
  }

  .notes-section {
    .section-header h4 {
      font-size: 1.3rem;
    }

    ul .note {
      font-size: 1.05rem;
    }
  }
}

@media (min-width: 1024px) {
  .patch-notes-container {
    padding: 4rem;
  }

  .patch-notes-header {
    .version-info h3 {
      font-size: 2rem;
    }
  }
}

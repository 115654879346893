// Base styles
.wiki-container {
  min-height: 100vh;
  background-color: #111827;
  transition: background-color 0.3s ease;
}

// Loading screen
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9fafb;

  .spinner {
    width: 50px;
    height: 50px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #4f46e5;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Navigation
.wiki-nav {
  background-color: #4f46e5;
  color: white;
  position: sticky;
  top: 0;
  z-index: 40;
  transition: background-color 0.3s ease;

  .nav-content {
    max-width: 80rem;
    margin: 0 auto;
    padding: 0 1rem;

    .nav-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 4rem;
    }
  }

  .nav-left {
    display: flex;
    align-items: center;
    gap: 1rem;

    h1 {
      font-size: 1.25rem;
      font-weight: 700;
    }
  }

  .nav-right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .mobile-menu-button {
    padding: 0.5rem;
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  .search-container {
    position: relative;
    display: none;

    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    .search-icon {
      position: absolute;
      left: 0.75rem;
      width: 1.25rem;
      height: 1.25rem;
      color: #9ca3af;
    }

    .search-input {
      padding: 0.5rem 1rem 0.5rem 2.5rem;
      border-radius: 0.5rem;
      color: #111827;
      width: 16rem;
      background-color: white;
      border: 1px solid #e5e7eb;
      transition: all 0.3s ease;

      &:focus {
        outline: none;
        border-color: #4f46e5;
        box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
      }
    }
  }

  // Update just the theme toggle button styles
  .theme-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 12px;
    color: white;
    background-color: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(8px);
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.1);

    svg {
      width: 20px;
      height: 20px;
      transition: transform 0.3s ease;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
      transform: translateY(-2px);
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);

      svg {
        transform: rotate(45deg);
      }
    }

    &:active {
      transform: translateY(0);
    }
  }
}

// Main Content
.main-content {
  max-width: 80rem;
  margin: 0 auto;
  padding: 2rem 1rem;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// Hero Section
.hero-section {
  text-align: center;
  margin-bottom: 3rem;

  h2 {
    font-size: 2.25rem;
    font-weight: 700;
    color: #111827;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.125rem;
    color: #4b5563;
  }
}

// Categories Grid
.categories-grid {
  display: grid;
  gap: 1.5rem;
  margin-bottom: 3rem;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.category-card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 1rem;
  transition: all 0.3s ease;
  overflow: hidden;
  height: 100%;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 20px -8px rgba(79, 70, 229, 0.2);
    border-color: #4f46e5;

    .category-icon {
      transform: scale(1.1) rotate(5deg);
    }

    .category-info h3 {
      color: #4f46e5;
    }
  }
}

.category-content {
  padding: 1.5rem;
  height: 100%;
}

.category-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1.25rem;
}

.category-icon {
  background-color: #eef2ff;
  color: #4f46e5;
  padding: 1rem;
  border-radius: 0.75rem;
  width: fit-content;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.category-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #111827;
    transition: color 0.3s ease;
    line-height: 1.4;
  }

  p {
    color: #6b7280;
    font-size: 0.925rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }
}

.subcategories-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 0.75rem;
  padding-top: 0.75rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.subcategories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.subcategory-tag {
  font-size: 0.75rem;
  padding: 0.4rem 0.75rem;
  background: rgba(96, 165, 250, 0.1);
  color: #60A5FA;
  border: 1px solid rgba(96, 165, 250, 0.2);
  border-radius: 2rem;
  transition: all 0.2s ease;
  cursor: pointer;

  &:hover {
    background: rgba(96, 165, 250, 0.15);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
}

// Dark mode styles for categories
.dark {
  .category-card {
    background-color: #1f2937;
    border-color: #374151;

    &:hover {
      border-color: #6366f1;
      box-shadow: 0 12px 20px -8px rgba(99, 102, 241, 0.3);

      .category-info h3 {
        color: #6366f1;
      }
    }
  }



  .category-info {
    h3 {
      color: #f3f4f6;
    }

    p {
      color: #9ca3af;
    }
  }

  .subcategories-list {
    border-top-color: #374151;
  }

  .subcategory-tag {
    background-color: #374151;
    color: #9ca3af;

    &:hover {
      background-color: #6366f1;
      color: #ffffff;
    }
  }
}

// Additional hover effects
@media (hover: hover) {
  .category-card {
    &:hover {
      .subcategory-tag {
        animation: subtleShake 0.5s ease;
      }
    }
  }
}

// Animation keyframes
@keyframes subtleShake {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-2px);
  }

  75% {
    transform: translateX(2px);
  }
}

// For better spacing in mobile view
@media (max-width: 639px) {
  .categories-grid {
    gap: 1rem;
  }

  .category-content {
    padding: 1.25rem;
  }

  .category-wrapper {
    gap: 1rem;
  }

  .category-info {
    h3 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.875rem;
    }
  }
}

// For larger screens
@media (min-width: 1280px) {
  .category-card {
    &:hover {
      transform: translateY(-6px);
    }
  }

  .category-content {
    padding: 1.75rem;
  }

  .category-info {
    h3 {
      font-size: 1.375rem;
    }

    p {
      font-size: 1rem;
    }
  }

  .subcategory-tag {
    font-size: 0.8125rem;
    padding: 0.5rem 1rem;
  }
}

// Featured Articles
.featured-articles {
  background-color: white;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  transition: all 0.3s ease;

  h3 {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
    color: #111827;
  }
}

.articles-list {
  display: flex;
  flex-direction: column;

  .article-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 0;
    cursor: pointer;
    transition: all 0.2s ease;
    border-bottom: 1px solid #e5e7eb;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      background-color: #f9fafb;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }
}

.article-title {
  color: #4f46e5;
  font-weight: 500;
}

.view-counter {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #6b7280;

  .eye-icon {
    width: 1rem;
    height: 1rem;
  }

  .view-count {
    font-size: 0.875rem;
    min-width: 4rem;
    text-align: right;
  }
}

// Mobile Menu
.mobile-menu-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(31, 41, 55, 0.75);
  z-index: 50;
  opacity: 0;
  animation: fadeIn 0.3s ease forwards;

  @media (min-width: 768px) {
    display: none;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 16rem;
  background-color: white;
  padding: 1rem;
  transform: translateX(-100%);
  animation: slideIn 0.3s ease forwards;

  .mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    h2 {
      font-size: 1.25rem;
      font-weight: 700;
      color: #111827;
    }

    button {
      padding: 0.5rem;
      border-radius: 0.375rem;
      transition: background-color 0.2s ease;

      &:hover {
        background-color: #f3f4f6;
      }
    }
  }
}

.mobile-menu-items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .mobile-menu-item {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    padding: 0.75rem;
    border-radius: 0.375rem;
    transition: all 0.2s ease;
    color: #111827;

    &:hover {
      background-color: #f3f4f6;
    }
  }
}

// Dark Mode Styles
.dark {
  background-color: #111827;
  color: #f9fafb;

  .wiki-nav {
    background-color: #1f2937;

    .search-input {
      background-color: #374151;
      border-color: #4b5563;
      color: white;

      &::placeholder {
        color: #9ca3af;
      }

      &:focus {
        border-color: #6366f1;
      }
    }
  }

  .hero-section {
    h2 {
      color: #f9fafb;
    }

    p {
      color: #9ca3af;
    }
  }

  .category-card {
    background-color: #1f2937;
    border-color: #374151;

    &:hover {
      box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.3);
    }
  }


  .category-info {
    h3 {
      color: #f9fafb;
    }

    p {
      color: #9ca3af;
    }
  }

  .subcategory-tag {
    background-color: #374151;
    color: #93c5fd;

    &:hover {
      background-color: #6366f1;
      color: #f9fafb;
    }
  }

  .featured-articles {
    background-color: #1f2937;
    border-color: #374151;

    h3 {
      color: #f9fafb;
    }
  }

  .article-item {
    border-color: #374151;

    &:hover {
      background-color: #374151;
    }
  }

  .article-title {
    color: #93c5fd;
  }

  .view-counter {
    color: #9ca3af;
  }

  .mobile-menu {
    background-color: #1f2937;

    .mobile-menu-header {
      h2 {
        color: #f9fafb;
      }
    }

    .mobile-menu-item {
      color: #f9fafb;

      &:hover {
        background-color: #374151;
      }
    }
  }
}

// Animations
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.category-icon {
  width: 100px; // Fixed width
  height: 100px; // Fixed height
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: all 0.3s ease;

  svg {
    width: 100px; // Match container size
    height: 100px;
  }

  img {
    width: 100px; // Match container size
    height: 100px;
    object-fit: cover; // Changed to cover to fill the space
  }

  // Remove background, padding, and border radius
  background-color: transparent;
  padding: 0;
  border-radius: 0;
}

// Mobile menu adjustments
.mobile-menu-items {
  .mobile-menu-item {
    .category-icon {
      width: 32px;
      height: 32px;
      padding: 0;

      svg,
      img {
        width: 32px;
        height: 32px;
      }
    }
  }
}

// Dark mode specific styles
.dark {
  .theme-toggle {
    background-color: rgba(0, 0, 0, 0.2);
    border-color: rgba(255, 255, 255, 0.05);

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.subcategories-list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
}

.subcategory-tag {
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  background: rgba(96, 165, 250, 0.1);
  color: #60A5FA;
  border: 1px solid rgba(96, 165, 250, 0.2);
  border-radius: 9999px;
  transition: all 0.2s ease;
  cursor: pointer;
  font-weight: 500;

  &:hover {
    background: rgba(96, 165, 250, 0.15);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
}

// Update the dark mode styles for subcategories
.dark {
  .subcategory-tag {
    background: rgba(96, 165, 250, 0.1);
    color: #60A5FA;
    border-color: rgba(96, 165, 250, 0.2);

    &:hover {
      background: rgba(96, 165, 250, 0.15);
    }
  }
}

// Responsive adjustments for subcategories
@media (max-width: 639px) {
  .subcategory-tag {
    font-size: 0.75rem;
    padding: 0.4rem 0.75rem;
  }
}

.category-page {
  .category-card {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .subcategory-tag {
    &.active {
      background: rgba(96, 165, 250, 0.2);
      border-color: #60A5FA;
    }
  }
}

.subcategory-content {
  padding: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #f8fafc;
    transition: color 0.3s ease;
    line-height: 1.4;
    margin: 0;
  }

  p {
    color: #94a3b8;
    font-size: 0.925rem;
    line-height: 1.5;
    margin: 0;
  }
}

// Responsive adjustments
@media (max-width: 639px) {
  .subcategories-grid {
    gap: 1rem;
  }

  .subcategory-card {
    .subcategory-content {
      padding: 1.25rem;

      h3 {
        font-size: 1.125rem;
      }

      p {
        font-size: 0.875rem;
      }
    }
  }
}

.back-button {
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1rem;
  color: #60A5FA;
  font-weight: 500;
  transition: all 0.2s ease;
  border-radius: 0.5rem;
  background: rgba(96, 165, 250, 0.1);
  border: 1px solid rgba(96, 165, 250, 0.2);

  &:hover {
    background: rgba(96, 165, 250, 0.15);
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }
}

.dark .back-button {
  color: #60A5FA;
  background: rgba(96, 165, 250, 0.1);
  border-color: rgba(96, 165, 250, 0.2);

  &:hover {
    background: rgba(96, 165, 250, 0.15);
  }
}

// Add to your Wiki.scss file

.subcategories-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  .subcategories-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: white;
    }
  }
}

.subcategory-item {
  .subcategory-card {
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0.75rem;
    transition: all 0.2s ease;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      background: rgba(255, 255, 255, 0.05);
      border-color: rgba(96, 165, 250, 0.3);
      transform: translateY(-1px);
    }
  }

  .subcategory-content {
    padding: 1rem;
  }

  // Styles for nested items
  .nested-subcategories {
    .subcategory-card {
      background: rgba(255, 255, 255, 0.02);
      margin-top: 0.5rem;
    }
  }
}

.subcategories-grid {
  display: grid;
  gap: 1.5rem;
  padding: 1.5rem;
  margin: 0 auto;
  max-width: 80rem; // Match the main content max-width from Wiki.scss
  width: 100%;

  @media (min-width: 640px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
  }

  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    padding: 2.5rem;
  }
}

// Add animation for expanding/collapsing
.nested-subcategories {
  animation: slideDown 0.2s ease-out;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.subcategory-card {
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  transition: all 0.3s ease;
  overflow: hidden;
  height: 100%;

  &:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 20px -8px rgba(79, 70, 229, 0.2);
    border-color: #4f46e5;
  }
}

.subcategories-grid {
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.expand-button {
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  color: #60A5FA;
  background: transparent;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.875rem;
  margin-top: 0.5rem;

  &:hover {
    background: rgba(96, 165, 250, 0.1);
    border-radius: 0.5rem;
  }

  svg {
    width: 1rem;
    height: 1rem;
    transition: transform 0.2s ease;
  }

  &:hover svg {
    transform: translateY(2px);
  }
}


.category-header {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem 0;

  .category-icon {
    flex-shrink: 0;
  }
}

.back-button {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  color: #60A5FA;
  background: rgba(96, 165, 250, 0.1);
  border: 1px solid rgba(96, 165, 250, 0.2);
  border-radius: 0.5rem;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(96, 165, 250, 0.15);
    transform: translateY(-1px);
  }
}

// Endpoint styles
.endpoint-content {
  img {
    display: block !important;
    margin: 0 !important;
    max-width: none !important;
  }

  p {
    margin: 1rem 0 !important;
  }

  .endpoint-header {
    margin-bottom: 2rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    h1 {
      font-size: 2.5rem;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 0.5rem;
    }

    .endpoint-meta {
      color: #94a3b8;
      font-size: 0.875rem;
    }
  }




  // Styles for rich text content
  h2 {
    font-size: 1.875rem;
    font-weight: 600;
    margin: 2rem 0 1rem;
    color: #ffffff;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 600;
    margin: 1.5rem 0 1rem;
    color: #ffffff;
  }

  p {
    // Reset any existing styles
    all: unset;
    display: block !important;
    width: 100% !important;
    margin: 1rem 0 !important;

    &:has(img) {
      display: flex !important;
      flex-direction: row !important;
      flex-wrap: wrap !important;
      gap: 0.5rem !important;
      align-items: flex-start !important;
    }
  }

  ul,
  ol {
    margin: 1.25rem 0;
    padding-left: 1.5rem;

    li {
      margin-bottom: 0.5rem;
    }
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  a {
    color: #60a5fa;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: #93c5fd;
      text-decoration: underline;
    }
  }

  img {
    // Reset any existing styles
    all: unset;
    display: block !important;
    width: calc((100% - 2.5rem) / 6) !important; // 6 images per row with gap
    height: 120px !important;
    object-fit: cover !important;
    border-radius: 0.25rem !important;
    border: 1px solid #334155 !important;
    transition: transform 0.2s ease !important;
    flex: 0 0 auto !important;

    &:hover {
      transform: scale(1.05) !important;
      border-color: #60a5fa !important;
    }
  }

  // Font size variations
  [style*="font-size"] {
    &[size="1"] {
      font-size: 0.875rem;
    }

    &[size="3"] {
      font-size: 1.125rem;
    }

    &[size="5"] {
      font-size: 1.25rem;
    }

    &[size="7"] {
      font-size: 1.5rem;
    }
  }

  // Alignment styles
  [style*="text-align: center"] {
    justify-content: center !important;
    text-align: center !important;
  }

  [style*="text-align: right"] {
    justify-content: flex-end !important;
    text-align: right !important;
  }

  [style*="text-align: left"] {
    justify-content: flex-start !important;
    text-align: left !important;
  }
}

// Override any prose styles
.prose {
  max-width: none !important;

  p {
    margin: 1rem 0 !important;
  }

  img {
    margin: 0 !important;
  }
}

// Loading and error states
.endpoint-loading,
.endpoint-error,
.endpoint-empty {
  text-align: center;
  padding: 4rem 2rem;
  color: #94a3b8;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-top-color: #60a5fa;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 1rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.endpoint-content {
  color: #f8fafc;

}

// Add these styles to your Wiki.scss

.endpoint-wrapper {
  min-height: calc(100vh - 64px);
  background-color: #0f172a;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;

  .mb-6 {
    position: relative;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .endpoint-content {
    width: 100%;
    max-width: 1200px;
    background: rgba(255, 255, 255, 0.03);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 1rem;
    padding: 2rem;

    .category-header {
      .category-icon {
        background: rgba(255, 255, 255, 0.03);
        border: 1px solid rgba(255, 255, 255, 0.1);
        border-radius: 0.75rem;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .endpoint-body {
    img {
      width: 200px !important; // Fixed width
      height: 120px !important;
      object-fit: cover !important;
      display: inline-block !important;
      margin: 2px !important;
      border-radius: 0.25rem !important;
      border: 1px solid #334155 !important;
      vertical-align: top !important;
    }
  
    p {
      margin: 0 !important;
      padding: 0 !important;
      width: 1020px !important; // Fixed width to fit 5 images (200px * 5 + margins)
      font-size: 0 !important;
  
      &:has(img) {
        display: block !important;
        margin: -2px !important;
      }
    }
  }
  
  .endpoint-wrapper {
    .endpoint-content {
      min-width: 1020px !important; // Ensure container is wide enough
      padding: 1.5rem !important;
      
      .prose {
        width: 1020px !important;
        max-width: none !important;
        
        img {
          width: 200px !important;
        }
      }
    }
  }
    // Headers
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: #f8fafc;
      font-weight: 600;
      margin: 2rem 0 1rem;

      &:first-child {
        margin-top: 0;
      }
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.75rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    h5 {
      font-size: 1.125rem;
    }

    h6 {
      font-size: 1rem;
    }

    // Paragraphs and spacing
    p {
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    // Lists
    ul,
    ol {
      margin: 1.5rem 0;
      padding-left: 1.5rem;
    }

    li {
      margin-bottom: 0.5rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul {
      list-style-type: disc;
    }

    ol {
      list-style-type: decimal;
    }

    // Font sizes
    [size="1"] {
      font-size: 0.875rem;
    }

    [size="3"] {
      font-size: 1.125rem;
    }

    [size="5"] {
      font-size: 1.25rem;
    }

    [size="7"] {
      font-size: 1.5rem;
    }

    // Text alignment with important to override any default styles
    [style*="text-align: center"] {
      text-align: center !important;
    }

    [style*="text-align: right"] {
      text-align: right !important;
    }

    [style*="text-align: left"] {
      text-align: left !important;
    }

    // Links
    a {
      color: #60a5fa;
      text-decoration: underline;
      transition: color 0.2s;

      &:hover {
        color: #93c5fd;
      }
    }

    // Images
    img {
      max-width: 100%;
      height: auto;
      border-radius: 0.5rem;
      margin: 1.5rem auto;
      display: block;
    }

    // Code blocks if needed
    pre,
    code {
      background: rgba(0, 0, 0, 0.2);
      border-radius: 0.375rem;
      padding: 0.2em 0.4em;
      font-family: monospace;
    }

    pre {
      padding: 1rem;
      margin: 1.5rem 0;
      overflow-x: auto;
    }
  }

// Dark mode styles
.dark {
  .subcategories-content {
    border-top-color: rgba(255, 255, 255, 0.1);
  }

  .subcategory-tag {
    background: rgba(96, 165, 250, 0.1);
    color: #60A5FA;
    border-color: rgba(96, 165, 250, 0.2);

    &:hover {
      background: rgba(96, 165, 250, 0.15);
    }
  }

  .expand-button {
    color: #60A5FA;

    &:hover {
      background: rgba(96, 165, 250, 0.1);
    }
  }
}

// Animation for expand/collapse
.subcategories-list {
  transition: all 0.3s ease;
}

.endpoint-wrapper {
  .endpoint-content {
    h1.endpoint-title {
      font-size: 2rem;
      font-weight: 600;
      color: #f8fafc;
      margin-bottom: 0.5rem;
    }

    // Add separator after title section
    .mb-6 {
      position: relative;
      margin-bottom: 2rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
.terms-and-conditions {
    background-color: #111827;
    color: #b8bbbf;
    padding: 32px 16px;
    font-family: "DM Sans", sans-serif;
    line-height: 1.6;
  
    h1 {
      font-family: "Roboto", sans-serif;
      font-size: 32px;
      margin-bottom: 1.5rem;
      color: #fff;
      text-align: center;  // Center the heading
    }
  
    .section {
      margin-bottom: 2rem;
      margin-left: 1rem;  // Add margin to the left
      margin-right: 1rem;  // Add margin to the right
  
      h2 {
        font-family: "Roboto", sans-serif;
        font-size: 24px;
        margin-bottom: 0.5rem;
        color: #fff;
      }
  
      p {
        font-size: 18px;
      }
    }
  
    @media screen and (min-width: 768px) {
      padding: 48px 64px;  // Add padding for larger screens
      .section {
        margin-left: 2rem;  // Increase margin for larger screens
        margin-right: 2rem;  // Increase margin for larger screens
      }
    }
  
    @media screen and (min-width: 1024px) {
      padding: 48px 128px;  // Add padding for larger screens
      .section {
        margin-left: 4rem;  // Increase margin for larger screens
        margin-right: 4rem;  // Increase margin for larger screens
      }
    }
  }
  
.crew-members {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.crew-members-header {
  text-align: center;
  font-size: 2rem;
  color: #ffffff;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.back-button {
  background: linear-gradient(145deg, #1f2937, #1a2433);
  color: #ffffff;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 2rem;
  
  &:hover {
    background: linear-gradient(145deg, #374151, #2d3748);
  }
}

.table-wrapper {
  background: rgba(19, 30, 50, 0.6);
  border-radius: 1rem;
  padding: 1rem;
  margin: 0 auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table-scroll {
  overflow-x: auto;
  border-radius: 0.5rem;
  background: rgba(11, 18, 33, 0.6);
  
  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(31, 41, 55, 0.5);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: #374151;
    border-radius: 4px;
  }
}

table {
  width: 100%;
  min-width: 800px;
  border-collapse: separate;
  border-spacing: 0;
  
  th, td {
    padding: 1rem;
    text-align: left;
    white-space: nowrap;
    border-bottom: 1px solid rgba(27, 44, 78, 0.6);
  }
  
  th {
    background-color: rgba(13, 29, 52, 0.95);
    color: #ffffff;
    font-weight: 600;
    position: sticky;
    top: 0;
    z-index: 10;
  }
  
  td {
    color: #e5e7eb;
  }
}

.row-even {
  background-color: rgba(26, 58, 102, 0.4);
}

.row-odd {
  background-color: rgba(15, 45, 82, 0.4);
}
@media screen and (min-width: 320px) {
  .about-container {
    background-color: #111827;
    padding: 24px;

    h1 {
      font-size: 24px;
      font-family: "Roboto", sans-serif;
      font-weight: 800;
      text-align: center;
    }
    p {
      color: #b8bbbf;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 768px) {
  .about-container {
    padding: 32px;
    h1 {
      font-size: 30px;
      text-align: left;

    }
    p {
      font-size: 20px;
    }
  }
}

@media screen and (min-width: 1440px) {
    .about-container{
        div{
            width: 80%;
            margin: auto;
        }
        
    }
}

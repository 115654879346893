body {
  margin: 0;
  padding: 0;
  background-color: #0f172a;
}

.KekkaiSolver {
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: #0f172a;
  color: #f0f0f0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
    letter-spacing: -0.02em;
    color: #f0f0f0;
    text-align: center;
  }

  .settingsContainer {
    width: 100%;
    max-width: 800px;
    background: rgba(17, 24, 39, 0.8);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);

    .settings {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem;

      .settingGroup {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        label {
          color: #f0f0f0;
          font-weight: 500;
          font-size: 1rem;
        }

        input[type="number"] {
          width: 50px;
          padding: 0.4rem;
          background: rgba(0, 0, 0, 0.3);
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          color: #f0f0f0;
          font-size: 1rem;
          text-align: center;

          &:focus {
            outline: none;
            border-color: rgba(255, 255, 255, 0.4);
          }
        }
      }

      button {
        padding: 0.4rem 1rem;
        background: #6366f1;
        color: #f0f0f0;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-weight: 500;
        transition: all 0.2s ease;
        font-size: 0.9rem;

        &:hover {
          background: #4f46e5;
          transform: translateY(-1px);
        }
      }
    }
  }

  .guessesTable {
    width: 100%;
    max-width: 800px;
    margin: 2rem auto;
    background: rgba(17, 24, 39, 0.8);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    
    table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0;
      
      th, td {
        padding: 0.75rem 1.25rem;
        text-align: center;
        border: 1px solid #2d3748;
        background: rgba(17, 24, 39, 0.7);
        font-size: 0.95rem;
      }

      th {
        background: rgba(31, 41, 55, 0.95);
        font-weight: 600;
        color: #f0f0f0;
        border-bottom: 2px solid #4a5568;
        text-transform: uppercase;
        letter-spacing: 0.05em;
      }

      td {
        border-bottom: 1px solid #2d3748;
      }

      tr:last-child td {
        border-bottom: 2px solid #4a5568;
      }

      .runesDisplay {
        display: flex;
        gap: 0.75rem;
        align-items: center;
        justify-content: center;
      }

      .runesRow {
        display: flex;
        gap: 0.75rem;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;

        img {
          width: 40px;
          height: 40px;
          object-fit: contain;
          filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.4));
        }
      }
    }
  }

  .currentGuess {
    width: 100%;
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
    background: rgba(17, 24, 39, 0.8);
    border-radius: 8px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);

    .guessDisplay {
      display: flex;
      align-items: center;
      gap: 1rem;
      margin-bottom: 2rem;
      font-size: 1.1rem;
      color: #f0f0f0;
    }

    .feedbackInputs {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .feedbackSection {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .inputGroup {
          display: flex;
          align-items: center;
          gap: 1rem;
          background: rgba(0, 0, 0, 0.2);
          padding: 1rem;
          border-radius: 8px;

          .feedbackLabel {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            label {
              color: #f0f0f0;
              font-weight: 500;
            }
          }

          input {
            width: 60px;
            padding: 0.5rem;
            background: rgba(0, 0, 0, 0.3);
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 6px;
            color: #f0f0f0;
            font-size: 1rem;

            &:focus {
              outline: none;
              border-color: rgba(255, 255, 255, 0.4);
            }
          }
        }
      }

      button {
        align-self: flex-end;
        padding: 0.75rem 2rem;
        background: #10b981;
        color: #f0f0f0;
        border: none;
        border-radius: 6px;
        cursor: pointer;
        font-weight: 500;
        transition: all 0.2s ease;
        text-transform: uppercase;
        letter-spacing: 0.05em;

        &:hover {
          background: #059669;
          transform: translateY(-1px);
          box-shadow: 0 0 15px rgba(16, 185, 129, 0.4);
        }
      }
    }
  }

  .runeIcon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    filter: drop-shadow(0 0 8px rgba(0, 0, 0, 0.4));
    border-radius: 50%;
    padding: 4px;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.15);
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }
  }

  .feedbackIcon {
    width: 25px;
    height: 25px;
    object-fit: contain;
    filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.4));
    margin: 0 2px;

    &.right {
      filter: drop-shadow(0 0 6px rgba(34, 197, 94, 0.5));
    }

    &.wrong {
      filter: drop-shadow(0 0 6px rgba(239, 68, 68, 0.5));
    }
  }

  .feedbackIcons {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;
  }

  .feedbackNumber {
    font-size: 1.2rem;
    font-weight: 600;
    color: #f0f0f0;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
    
    &:nth-child(2) {  // Right position column
      color: #22c55e;  // Green color
    }
    
    &:nth-child(3) {  // Wrong position column
      color: #fbbf24;  // Yellow color
    }
  }

  .error {
    width: 100%;
    max-width: 800px;
    padding: 1rem;
    margin: 1rem auto;
    background: rgba(239, 68, 68, 0.1);
    color: #ef4444;
    border-radius: 8px;
    border: 1px solid rgba(239, 68, 68, 0.2);
    text-align: center;
  }

  .success {
    width: 100%;
    max-width: 800px;
    padding: 1rem;
    margin: 1rem auto;
    background: rgba(16, 185, 129, 0.1);
    color: #10b981;
    border-radius: 8px;
    border: 1px solid rgba(16, 185, 129, 0.2);
    text-align: center;
  }
}

.category-manager {
  padding: 1.5rem;

  .category-header {
    margin-bottom: 2rem;
    
    h2 {
      font-size: 1.5rem;
      font-weight: 600;
      color: #1f2937;
    }
  }

  // Error message styling
  .error {
    text-align: left;
    padding: 1rem;
    color: #dc2626;
    background-color: #fee2e2;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }

  .add-category-form {
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;

    .form-group {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr auto;
      gap: 1rem;
      align-items: start;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }

      input {
        padding: 0.75rem;
        border: 1px solid #e5e7eb;
        border-radius: 0.375rem;
        font-size: 0.875rem;
        transition: all 0.2s ease;

        &:focus {
          outline: none;
          border-color: #4f46e5;
          box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
        }

        &::placeholder {
          color: #9ca3af;
        }
      }

      .image-upload {
        position: relative;
        
        .upload-button {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          padding: 0.75rem 1.5rem;
          background-color: #f3f4f6;
          border: 1px dashed #e5e7eb;
          border-radius: 0.375rem;
          color: #4f46e5;
          font-size: 0.875rem;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;

          &:hover {
            background-color: #e5e7eb;
            border-color: #4f46e5;
          }

          input[type="file"] {
            display: none;
          }

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }
        }

        .image-preview {
          margin-top: 0.75rem;
          width: 80px;
          height: 80px;
          border-radius: 0.375rem;
          overflow: hidden;
          border: 2px solid #e5e7eb;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .add-button {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 0.75rem 1.5rem;
        background-color: #4f46e5;
        color: white;
        border-radius: 0.375rem;
        font-weight: 500;
        transition: all 0.2s ease;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }

        &:not(:disabled):hover {
          background-color: #4338ca;
        }

        svg {
          width: 1.25rem;
          height: 1.25rem;
        }
      }
    }
  }

  .categories-list {
    display: grid;
    gap: 1rem;

    .category-item {
      background-color: white;
      padding: 1.5rem;
      border-radius: 0.5rem;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      transition: all 0.2s ease;

      &:hover {
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      .category-info {
        display: flex;
        gap: 1rem;
        align-items: flex-start;
        flex: 1;

        .category-icon {
          width: 100px;
          height: 100px;
          border-radius: 1rem;
          overflow: hidden;
          border: 1px solid #e5e7eb;
          flex-shrink: 0;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .category-text {
          flex: 1;

          h3 {
            font-size: 1.125rem;
            font-weight: 600;
            color: #1f2937;
            margin-bottom: 0.25rem;
          }

          p {
            color: #6b7280;
            font-size: 0.875rem;
            margin-bottom: 0.5rem;
          }

          .metadata {
            .flex {
              display: flex;
              align-items: center;

              svg {
                color: #4f46e5;
              }
            }

            .text-xs {
              margin-top: 0.25rem;
              color: #6b7280;
            }
          }
        }
      }

      .actions {
        display: flex;
        gap: 0.5rem;

        button {
          padding: 0.5rem;
          border-radius: 9999px; // For perfect circle
          color: #6b7280;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            width: 1.25rem;
            height: 1.25rem;
          }

          &:hover {
            background-color: #f3f4f6;
            color: #4f46e5;

            &.delete {
              background-color: #fee2e2;
              color: #dc2626;
            }
          }
        }
      }

      .edit-form {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        input {
          padding: 0.75rem;
          border: 1px solid #e5e7eb;
          border-radius: 0.375rem;
          font-size: 0.875rem;
          width: 100%;

          &:focus {
            outline: none;
            border-color: #4f46e5;
            box-shadow: 0 0 0 2px rgba(79, 70, 229, 0.2);
          }
        }

        .image-upload {
          margin: 0.5rem 0;

          .upload-button {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.75rem 1.5rem;
            background-color: #f3f4f6;
            border: 1px dashed #e5e7eb;
            border-radius: 0.375rem;
            color: #4f46e5;
            font-size: 0.875rem;
            cursor: pointer;
            transition: all 0.2s ease;

            &:hover {
              background-color: #e5e7eb;
              border-color: #4f46e5;
            }
          }

          .image-preview {
            margin-top: 0.75rem;
            width: 60px;
            height: 60px;
            border-radius: 0.375rem;
            overflow: hidden;
            border: 2px solid #e5e7eb;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .actions {
          display: flex;
          gap: 0.5rem;
          justify-content: flex-end;
          margin-top: 1rem;

          button {
            padding: 0.5rem;
            border-radius: 9999px;
            transition: all 0.2s ease;
            display: flex;
            align-items: center;
            justify-content: center;

            &[type="button"] {
              color: #6b7280;
              &:hover {
                background-color: #f3f4f6;
              }
            }

            &[type="submit"] {
              color: #10b981;
              &:hover {
                background-color: #ecfdf5;
              }
            }

            svg {
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
    }
  }

  // Loading state
  .loading {
    text-align: center;
    padding: 2rem;
    color: #6b7280;
  }

  // Empty state
  .text-center {
    text-align: center;
    padding: 2rem;
    color: #6b7280;
  }
}

// Dark mode support
.dark {
  .category-manager {
    .category-header h2 {
      color: #f9fafb;
    }

    .add-category-form {
      background-color: #1f2937;

      input {
        background-color: #374151;
        border-color: #4b5563;
        color: white;

        &::placeholder {
          color: #9ca3af;
        }
      }

      .image-upload {
        .upload-button {
          background-color: #374151;
          border-color: #4b5563;
          color: #818cf8;

          &:hover {
            background-color: #4b5563;
            border-color: #818cf8;
          }
        }
      }
    }

    .categories-list {
      .category-item {
        background-color: #1f2937;

        .category-info {
          .category-text {
            h3 {
              color: #f9fafb;
            }

            p {
              color: #9ca3af;
            }

            .metadata {
              color: #9ca3af;

              svg {
                color: #818cf8;
              }
            }
          }
        }

        .actions button {
          color: #9ca3af;
          
          &:hover {
            background-color: #374151;
            color: #818cf8;

            &.delete {
              background-color: #7f1d1d;
              color: #fecaca;
            }
          }
        }

        .edit-form {
          input {
            background-color: #374151;
            border-color: #4b5563;
            color: white;

            &::placeholder {
              color: #9ca3af;
            }
          }

          .image-upload {
            .upload-button {
              background-color: #374151;
              border-color: #4b5563;
              color: #818cf8;

              &:hover {
                background-color: #4b5563;
                border-color: #818cf8;
              }
            }
          }
        }
      }
    }
  }
}
.item-manager-panel {
  .data-item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #111827;
    border-radius: 8px;
    transition: all 0.2s ease;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      background-color: #1a2234;
    }

    label {
      font-weight: 600;
      color: #9ca3af;
      min-width: 160px;
      flex-shrink: 0;
      text-align: center;
    }

    .category-selector {
      display: flex;
      gap: 12px;
      flex: 1;
      justify-content: center;

      button {
        background-color: #374151;
        color: #9ca3af;
        border: none;
        padding: 0.75rem 1.5rem;
        border-radius: 8px;
        cursor: pointer;
        transition: all 0.2s ease;
        font-weight: 500;
        min-width: 120px;
        white-space: nowrap;

        &:hover:not(:disabled) {
          transform: translateY(-1px);
          box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
        }

        &.active {
          background-color: #3b82f6;
          color: #fff;
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }

    .form-container {
      flex: 1;

      form {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        max-width: 600px;
        margin: 0 auto;

        .form-group {
          display: flex;
          flex-direction: column;
          gap: 8px;

          label {
            font-size: 0.875rem;
            color: #9ca3af;
            font-weight: 500;
            min-width: unset;
            text-align: left;
          }

          select, input {
            background-color: #1f2937;
            border: 1px solid #374151;
            color: #fff;
            padding: 0.75rem 1rem;
            border-radius: 8px;
            font-size: 0.875rem;
            transition: all 0.2s ease;
            width: 100%;

            &:focus {
              outline: none;
              border-color: #3b82f6;
              box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
            }

            &::placeholder {
              color: #6b7280;
            }
          }

          select {
            cursor: pointer;

            option {
              background-color: #1f2937;
              color: #fff;
            }
          }

          input[type="number"] {
            &::-webkit-inner-spin-button,
            &::-webkit-outer-spin-button {
              opacity: 1;
              background-color: #374151;
              border-left: 1px solid #4b5563;
              cursor: pointer;
            }
          }
        }

        button[type="submit"] {
          background-color: #3b82f6;
          color: white;
          border: none;
          padding: 0.75rem 1.5rem;
          border-radius: 8px;
          font-weight: 500;
          cursor: pointer;
          transition: all 0.2s ease;
          align-self: center;
          min-width: 200px;
          white-space: nowrap;

          &:hover:not(:disabled) {
            background-color: #2563eb;
            transform: translateY(-1px);
            box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
          }

          &:disabled {
            opacity: 0.7;
            cursor: not-allowed;
          }
        }
      }
    }
  }

  .status-message {
    margin-top: 16px;
    font-size: 0.875rem;
    padding: 12px;
    border-radius: 8px;
    text-align: center;
    
    &.error {
      background-color: rgba(220, 38, 38, 0.1);
      color: #ef4444;
      border: 1px solid rgba(220, 38, 38, 0.2);
    }
    
    &.success {
      background-color: rgba(5, 150, 105, 0.1);
      color: #10b981;
      border: 1px solid rgba(5, 150, 105, 0.2);
    }
  }
}

@media screen and (max-width: 767px) {
  .item-manager-panel {
    .data-item {
      flex-direction: column;
      align-items: stretch;

      label {
        min-width: unset;
        margin-bottom: 12px;
      }

      .category-selector {
        flex-direction: column;

        button {
          width: 100%;
        }
      }
    }
  }
}

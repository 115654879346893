@media screen and (min-width: 320px) {
  .footer {
    background-color: #111827;
    padding: 32px 16px;

    .footer-container {
      h3 {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
        margin-top: 2rem;
      }
      ul {
        list-style-type: none;
        font-family: "DM Sans", sans-serif;
        font-size: 18px;
        a {
          color: #b8bbbf;
          text-decoration: none;
          &:hover {
            color: white;
          }
        }
      }
      .first-container {
        img {
          width: 120px;
        }
        p {
          font-family: "DM Sans", sans-serif;
          margin-top: 1rem;
          color: #b8bbbf;
        }
      }

      .fourth-container {
        a {
          font-size: 40px;
          margin-right: 1rem;
          &:hover{
            opacity: 0.5;
          }
          &:nth-child(2) {
            color: #a855f7;
          }
          &:nth-child(3) {
            color: #1976d2;
          }
          &:nth-child(4) {
            color: #ff0000;
          }
          &:nth-child(5) {
            color: #ff00d9;
          }
          &:nth-child(6) {
            color: #c7c7c7;
          }
        }
      }
    }
    .rights {
      font-family: "DM Sans", sans-serif;
      font-size: 14px;
      color: #b8bbbf;
      text-align: center;
      padding-top: 2rem;
    }
  }
}

@media screen and (min-width: 768px) {
  .footer {
    padding: 48px 16px;
    .footer-container {
      display: grid;
      grid-auto-columns: 1fr;
      grid-template-columns: repeat(4, 1fr);  
      gap: 0px 0px;
      width: 100%;
      height: 100%;

      .first-container {
        p {
          width: 80%;
        }
      }

      .second-container {
        margin-left: 50px;
        margin-right: 50px;
      }

      .third-container {
        margin-left: 50px;
        margin-right: 50px;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .footer {
    .footer-container {
      .first-container {
        p {
          width: 90%;
        }
      }
      .second-container, .third-container {
        margin-left: 50px;
        margin-right: 50px;
      }
    }
  }
}

@media screen and (min-width: 1440px) {
  .footer {
    .footer-container {
      padding-left: 80px;
      padding-right: 80px;
      .second-container, .third-container {
        margin-left: 100px;
        margin-right: 100px;
      }
    }
  }
}

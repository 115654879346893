:root {
  --transition-duration: 0.3s;
  --transition-timing: ease;
}

html {
  --sidebar-width: 280px;
  --header-height: 64px;

  // Light theme variables
  --bg-primary: #f9fafb;
  --bg-secondary: #ffffff;
  --text-primary: #111827;
  --text-secondary: #4b5563;
  --border-color: #e5e7eb;
  --sidebar-gradient-start: #1e1b4b;
  --sidebar-gradient-end: #312e81;
  --accent-color: #4f46e5;
  --accent-hover: #4338ca;
  --danger-color: #ef4444;
  --danger-hover: #dc2626;
  --input-bg: #ffffff;
  --input-border: #e5e7eb;
  --input-text: #111827;
  --input-placeholder: #9ca3af;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);

  // Add transition for all CSS variables
  transition:
    --bg-primary var(--transition-duration) var(--transition-timing),
    --bg-secondary var(--transition-duration) var(--transition-timing),
    --text-primary var(--transition-duration) var(--transition-timing),
    --text-secondary var(--transition-duration) var(--transition-timing),
    --border-color var(--transition-duration) var(--transition-timing),
    --sidebar-gradient-start var(--transition-duration) var(--transition-timing),
    --sidebar-gradient-end var(--transition-duration) var(--transition-timing),
    --accent-color var(--transition-duration) var(--transition-timing),
    --accent-hover var(--transition-duration) var(--transition-timing),
    --input-bg var(--transition-duration) var(--transition-timing),
    --input-border var(--transition-duration) var(--transition-timing),
    --input-text var(--transition-duration) var(--transition-timing),
    --input-placeholder var(--transition-duration) var(--transition-timing),
    --shadow-sm var(--transition-duration) var(--transition-timing),
    --shadow-md var(--transition-duration) var(--transition-timing);
}

html.dark {
  --bg-primary: #111827;
  --bg-secondary: #1e293b;
  --text-primary: #f9fafb;
  --text-secondary: #e5e7eb;
  --border-color: rgba(255, 255, 255, 0.1);
  --sidebar-gradient-start: #0f172a;
  --sidebar-gradient-end: #1e293b;
  --accent-color: #818cf8;
  --accent-hover: #6366f1;
  --danger-color: #f87171;
  --danger-hover: #ef4444;
  --input-bg: #1e293b;
  --input-border: rgba(255, 255, 255, 0.1);
  --input-text: #f9fafb;
  --input-placeholder: #64748b;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
}

// Variables
:root {
  --sidebar-width: 280px;
  --header-height: 64px;

  // Light theme variables
  --bg-primary: #f9fafb;
  --bg-secondary: #ffffff;
  --text-primary: #111827;
  --text-secondary: #4b5563;
  --border-color: #e5e7eb;
  --sidebar-gradient-start: #1e1b4b;
  --sidebar-gradient-end: #312e81;
  --accent-color: #4f46e5;
  --accent-hover: #4338ca;
  --danger-color: #ef4444;
  --danger-hover: #dc2626;
  --input-bg: #ffffff;
  --input-border: #e5e7eb;
  --input-text: #111827;
  --input-placeholder: #9ca3af;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
}

// Dark theme variables
.dark {
  --bg-primary: #111827;
  --bg-secondary: #1e293b;
  --text-primary: #f9fafb;
  --text-secondary: #e5e7eb;
  --border-color: rgba(255, 255, 255, 0.1);
  --sidebar-gradient-start: #0f172a;
  --sidebar-gradient-end: #1e293b;
  --accent-color: #818cf8;
  --accent-hover: #6366f1;
  --danger-color: #f87171;
  --danger-hover: #ef4444;
  --input-bg: #1e293b;
  --input-border: rgba(255, 255, 255, 0.1);
  --input-text: #f9fafb;
  --input-placeholder: #64748b;
  --shadow-sm: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  --shadow-md: 0 4px 6px -1px rgba(0, 0, 0, 0.2);
}

// Base container
.admin-container {
  display: flex;
  min-height: 100vh;
  background-color: var(--bg-primary);
  position: relative;
  transition: background-color 0.3s ease, color 0.3s ease;
  color: var(--text-primary);

  // Sidebar styles
  .admin-sidebar {
    width: var(--sidebar-width);
    min-height: 100vh;
    background: linear-gradient(180deg, var(--sidebar-gradient-start) 0%, var(--sidebar-gradient-end) 100%);
    color: #f9fafb;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    border-right: 1px solid var(--border-color);
    z-index: 40;
    transition: background 0.3s ease;

    // Custom scrollbar
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(255, 255, 255, 0.05);
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.1);
      border-radius: 3px;

      &:hover {
        background: rgba(255, 255, 255, 0.2);
      }
    }

    .sidebar-header {
      padding: 1.5rem;
      border-bottom: 1px solid var(--border-color);

      .header-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1.5rem;

        h1 {
          font-size: 1.5rem;
          font-weight: 700;
          color: white;
          margin: 0;
        }

        .theme-toggle {
          background: transparent;
          border: none;
          color: #f9fafb;
          cursor: pointer;
          padding: 0.5rem;
          border-radius: 0.375rem;
          transition: all 0.2s ease;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            background-color: rgba(255, 255, 255, 0.1);
            transform: rotate(15deg);
          }

          .theme-icon {
            width: 1.25rem;
            height: 1.25rem;
          }
        }
      }

      .admin-avatar {
        width: 48px;
        height: 48px;
        background: var(--accent-color);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.75rem;

        .avatar-icon {
          color: white;
          width: 24px;
          height: 24px;
        }
      }

      .admin-profile {
        .admin-name {
          font-weight: 600;
          color: white;
          display: block;
          margin-bottom: 0.5rem;
        }

        .admin-info {
          .admin-details {
            margin-top: 0.75rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .time-info,
            .last-activity {
              display: flex;
              align-items: center;
              gap: 0.5rem;
              font-size: 0.75rem;
              color: #cbd5e1;
              padding: 0.5rem;
              border-radius: 0.375rem;
              background: rgba(255, 255, 255, 0.05);
              transition: background-color 0.2s ease;

              &:hover {
                background: rgba(255, 255, 255, 0.1);
              }

              .icon {
                color: #94a3b8;
              }

              span {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
          }
        }
      }
    }

    .sidebar-nav {
      padding: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      flex-grow: 1;

      .nav-items-group {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .nav-item {
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        color: #cbd5e1;
        transition: all 0.2s ease;
        font-weight: 500;
        border: none;
        background: transparent;
        width: 100%;
        text-align: left;
        cursor: pointer;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
          color: white;
          transform: translateX(4px);
        }

        &.active {
          background: var(--accent-color);
          color: white;
          box-shadow: var(--shadow-md);

          &:hover {
            transform: translateX(4px) translateY(-2px);
          }
        }

        svg {
          width: 1.25rem;
          height: 1.25rem;
          flex-shrink: 0;
        }
      }

      .logout-button {
        margin-top: auto;
        display: flex;
        align-items: center;
        gap: 0.75rem;
        padding: 0.75rem 1rem;
        border-radius: 0.5rem;
        color: #fecaca;
        background: rgba(239, 68, 68, 0.1);
        transition: all 0.2s ease;
        font-weight: 500;
        border: 1px solid rgba(239, 68, 68, 0.2);
        cursor: pointer;

        &:hover {
          background: var(--danger-color);
          color: white;
          transform: translateX(4px);
        }

        svg {
          width: 1.25rem;
          height: 1.25rem;
          flex-shrink: 0;
        }
      }
    }
  }

  // Main content area
  .admin-main {
    flex: 1;
    margin-left: var(--sidebar-width);
    min-height: 100vh;
    background-color: var(--bg-primary);
    transition: background-color 0.3s ease;

    .main-header {
      background-color: var(--bg-secondary);
      border-bottom: 1px solid var(--border-color);
      padding: 1rem 1.5rem;
      position: sticky;
      top: 0;
      z-index: 30;
      box-shadow: var(--shadow-sm);
      transition: background-color 0.3s ease, border-color 0.3s ease;

      .header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;

        .search-bar {
          position: relative;
          max-width: 32rem;
          flex: 1;

          .search-icon {
            position: absolute;
            left: 1rem;
            top: 50%;
            transform: translateY(-50%);
            color: var(--text-secondary);
            width: 1.25rem;
            height: 1.25rem;
            transition: color 0.3s ease;
          }

          input {
            width: 100%;
            padding: 0.75rem 1rem 0.75rem 2.75rem;
            border: 1px solid var(--input-border);
            border-radius: 0.5rem;
            font-size: 0.875rem;
            background-color: var(--input-bg);
            color: var(--input-text);
            transition: all 0.2s ease;

            &:focus {
              outline: none;
              border-color: var(--accent-color);
              box-shadow: 0 0 0 2px rgba(var(--accent-color), 0.2);
            }

            &::placeholder {
              color: var(--input-placeholder);
            }
          }
        }
      }
    }

    .main-content {
      padding: 2rem 1.5rem;
      animation: fadeIn 0.3s ease;
      background-color: var(--bg-primary);
      min-height: calc(100vh - var(--header-height));
      transition: background-color 0.3s ease;
    }
  }
}

// Loading screen
.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: var(--bg-primary);
  transition: background-color 0.3s ease;

  .spinner {
    width: 40px;
    height: 40px;
    border: 3px solid var(--border-color);
    border-top-color: var(--accent-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
}

// Animations
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

// Responsive styles
@media (max-width: 1024px) {
  :root {
    --sidebar-width: 240px;
  }
}

@media (max-width: 768px) {
  .admin-container {
    .admin-sidebar {
      transform: translateX(-100%);
      transition: transform 0.3s ease;

      &.open {
        transform: translateX(0);
      }
    }

    .admin-main {
      margin-left: 0;
    }
  }
}

// Card styles
.card {
  background-color: var(--bg-secondary);
  border-radius: 0.5rem;
  box-shadow: var(--shadow-sm);
  border: 1px solid var(--border-color);
  transition: all 0.3s ease;

  &:hover {
    box-shadow: var(--shadow-md);
  }
}

// Table styles
.table-container {
  background-color: var(--bg-secondary);
  border-radius: 0.5rem;
  overflow: hidden;

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      background-color: var(--bg-primary);
      color: var(--text-secondary);
      font-weight: 500;
      text-align: left;
      padding: 0.75rem 1rem;
      border-bottom: 1px solid var(--border-color);
    }

    td {
      padding: 0.75rem 1rem;
      border-bottom: 1px solid var(--border-color);
      color: var(--text-primary);
    }

    tbody tr {
      transition: background-color 0.2s ease;

      &:hover {
        background-color: var(--bg-primary);
      }
    }
  }
}

// Form elements
.form-group {
  margin-bottom: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-secondary);
    font-weight: 500;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 0.75rem 1rem;
    border: 1px solid var(--input-border);
    border-radius: 0.5rem;
    background-color: var(--input-bg);
    color: var(--input-text);
    transition: all 0.2s ease;

    &:focus {
      outline: none;
      border-color: var(--accent-color);
      box-shadow: 0 0 0 2px rgba(var(--accent-color), 0.2);
    }

    &::placeholder {
      color: var(--input-placeholder);
    }
  }
}

// Buttons
.button {
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  &.primary {
    background-color: var(--accent-color);
    color: white;
    border: none;
  }
}
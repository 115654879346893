/* Dashboard.scss */
.content-container {
    background-color: #111827;
    color: #fff;
    min-height: 100vh;
    padding: 0 15px;
}

.h1-dashboard {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 20px;
    background-color: #1f2937;
    color: #fff;
    padding: 20px;
    font-size: 1.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);

    .title {
        font-weight: 600;
    }

    .logout-btn {
        background-color: #dc2626;
        color: white;
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        transition: all 0.2s ease;

        &:hover {
            background-color: #b91c1c;
            transform: translateY(-1px);
        }

        &:active {
            transform: translateY(0);
        }
    }
}

.data-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto 20px;
    padding: 20px;
    background-color: #1f2937;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
    border-radius: 12px;

    &:last-child {
        margin-bottom: 0;
    }
}

.data-item {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    padding: 16px;
    background-color: #111827;
    border-radius: 8px;
    transition: all 0.2s ease;

    &:last-child {
        margin-bottom: 0;
    }

    &:hover {
        background-color: #1a2234;
    }

    label {
        font-weight: 600;
        color: #9ca3af;
        min-width: 160px;
        flex-shrink: 0;
    }

    span {
        flex: 1;
        color: #fff;
        font-weight: 500;
    }

    img {
        width: auto;
        height: 24px;
        margin-left: auto;
    }

    button.change-username-btn {
        background-color: #3b82f6;
        color: white;
        border: none;
        padding: 8px 16px;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 500;
        margin-left: auto;
        white-space: nowrap;
        transition: all 0.2s ease;

        &:hover {
            background-color: #2563eb;
            transform: translateY(-1px);
        }

        &:active {
            transform: translateY(0);
        }
    }
}

/* Character Cards Styling */
.character-cards {
    margin-top: 0;
    
    h2 {
        font-size: 1.25rem;
        font-weight: bold;
        margin-bottom: 1rem;
        color: #fff;
    }
}

.character-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
    grid-auto-flow: dense;
}

.character-card {
    background-color: #111827;
    border-radius: 8px;
    padding: 1rem;
    transition: transform 0.2s ease;
    display: flex;
    flex-direction: column;
    
    &:hover {
        transform: translateY(-2px);
    }

    /* If card has clan, crew, or arena sections, make it span 2 rows */
    &:has(.clan-section),
    &:has(.crew-section),
    &:has(.arena-section) {
        grid-row: span 2;
    }

    .card-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 1rem;

        .name-section {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            img {
                width: 2rem;
                height: 2rem;
            }

            .name-id {
                h3 {
                    color: #fff;
                    font-size: 1.25rem;
                    font-weight: bold;
                    margin: 0;
                }

                .char-id {
                    color: #9ca3af;
                    font-size: 0.875rem;
                }
            }
        }

        .class-image {
            width: 2rem;
            height: 2rem;
        }
    }

    .stats-grid {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 1rem;

        .stat-row {
            display: flex;
            gap: 1rem;
            width: 100%;

            .stat-item {
                flex: 1;

                .label {
                    color: #9ca3af;
                    font-size: 0.875rem;
                    margin-bottom: 0.25rem;
                }

                .value {
                    display: flex;
                    align-items: center;
                    gap: 0.25rem;
                    color: #fff;
                    font-weight: 600;

                    img {
                        width: 1rem;
                        height: 1rem;
                    }
                }

                &.xp-item {
                    flex: 2;

                    .xp-progress {
                        margin-top: 0.5rem;
                        width: 100%;
                        height: 4px;
                        background-color: #374151;
                        border-radius: 2px;
                        overflow: hidden;

                        .xp-progress-bar {
                            height: 100%;
                            background-color: #10b981;
                            transition: width 0.3s ease;
                        }
                    }
                }
            }
        }
    }

    .attributes {
        .section {
            margin-bottom: 1rem;

            &:last-child {
                margin-bottom: 0;
            }

            .label {
                color: #9ca3af;
                font-size: 0.875rem;
                margin-bottom: 0.25rem;
            }

            .icons {
                display: flex;
                gap: 0.5rem;
                align-items: center;

                img {
                    width: 1.5rem;
                    height: 1.5rem;
                }
            }
        }
    }

    .clan-section,
    .crew-section,
    .arena-section {
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid #374151;

        .section-title {
            color: #fff;
            font-size: 1.1rem;
            font-weight: 600;
            margin-bottom: 1rem;
            padding-bottom: 0.5rem;
            border-bottom: 2px solid #3b82f6;
        }

        .section-stats {
            display: flex;
            flex-direction: column;
            gap: 0.75rem;

            .stats-row {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                .stat {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    flex: 1;

                    img {
                        width: 1.25rem;
                        height: 1.25rem;
                    }

                    span {
                        color: #fff;
                        font-size: 0.875rem;

                        &.label {
                            color: #9ca3af;
                            margin-right: 0.25rem;
                        }
                    }
                }
            }
        }
    }

    .clan-section,
    .crew-section {
        .section-header {
            display: flex;
            align-items: center;
            margin-bottom: 1rem;

            img {
                width: 1.5rem;
                height: 1.5rem;
            }

            .name-rank {
                display: flex;
                flex-direction: column;
                margin-left: 0.75rem;

                .name {
                    color: #fff;
                    font-weight: 600;
                    font-size: 1rem;
                }

                .rank {
                    color: #9ca3af;
                    font-size: 0.875rem;
                    margin-top: 0.125rem;
                }
            }
        }
    }

    .arena-section {
        .section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.5rem;
            margin-bottom: 1rem;
    
            .arena-header-left {
                img.arena-icon {
                    width: 3rem;
                    height: 3rem;
                }
            }

            .arena-header-center {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;

                .village-name {
                    color: #fff;
                    font-weight: 600;
                    font-size: 1rem;
                }

                .player-rank {
                    color: #9ca3af;
                    font-size: 0.875rem;
                    margin-top: 0.125rem;
                }
            }
    
            .arena-header-right {
                img.village-icon {
                    width: 2rem;
                    height: 2rem;
                }
            }
        }
    }
}

/* Media Queries */
@media screen and (min-width: 768px) {
    .content-container {
        padding: 20px 30px;
    }

    .h1-dashboard {
        padding: 25px 30px;
        font-size: 2rem;
    }

    .data-container {
        padding: 25px;
    }
}

@media screen and (max-width: 767px) {
    .data-item {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        label {
            min-width: unset;
        }

        span {
            width: 100%;
        }

        button.change-username-btn,
        img {
            margin-left: 0;
            margin-top: 8px;
        }
    }

    .character-grid {
        grid-template-columns: 1fr;
    }
}
